import crypto from 'crypto';
import store from "../store";
import controller from "./controller";
import { external_api } from './api_handle';

export const RANDOM_STRING = crypto.randomBytes(20).toString('hex');
export const USER_AGENT = navigator.userAgent;

export const string_to_hash_code = (input=RANDOM_STRING) => {
  var hash = 0;
  if (input?.length == 0) return hash;
  for (let i = 0; i < input.length; i++) {
    const char = input.charCodeAt(i);
    hash = ((hash<<5)-hash)+char;
    hash = hash & hash;
  }
  return hash;
}
export const AGENT_HASH = string_to_hash_code(USER_AGENT)
export const TOKEN_HASH = string_to_hash_code(RANDOM_STRING)

export const S3BUCKET = "https://atlasia.s3.ca-central-1.amazonaws.com/";
export const S3LINK = "https://b2qfgq7ull.execute-api.ca-central-1.amazonaws.com/atlasia/";
export const UPLOADER = S3LINK + "uploader?key=";
export const FILTER = S3LINK + "filter?key=";

export const S3_INDEX =  encodeURIComponent(btoa(`${process.env.NODE_ENV}/${AGENT_HASH}/${TOKEN_HASH}/`));
export const UPLOAD_URL = UPLOADER + S3_INDEX


export const load_file_from_s3 = (property_ID) => {
  const FILTER_URL = FILTER + S3_INDEX;

  external_api.get(FILTER_URL).then(res => {
    if (res.data?.body?.length) {
      const files = res.data.body.map((file_name) => S3BUCKET + file_name);
      const last_photo = files[files.length-1]
      store.commit('set_file_url', last_photo)

      if (property_ID) {
        const property = store.state.properties?.find(p => p._id?.toString() === property_ID)
        if (last_photo !== property?.photo) {
          controller.send_property_photo(property?._id, last_photo)
        }
      }
    }
  })
}


const aws_lambda = {
  string_to_hash_code: string_to_hash_code,
  AGENT_HASH: AGENT_HASH,
  TOKEN_HASH: TOKEN_HASH,
  UPLOAD_URL: UPLOAD_URL,
  load_file_from_s3: load_file_from_s3
}

export default aws_lambda;
