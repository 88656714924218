import router from './router'
import store from './store'
import { getAuthorizedUser, logoutUser } from './helper/controller'

router.beforeEach(async (to, from, next) => {
  if (to.meta.auth === false) {
    if (to.name === 'TenantAgreement') {
      if (localStorage.getItem('authorized')) {
        return next('Rentals')
      }
    }

    return next()
  }

  if (localStorage.getItem('authorized')) {
    store.commit('set_authorized', true)

    const account = store.getters.account;
 
    if (!account.email || !account.userID) {
      await getAuthorizedUser()
    }
    return next()
  } else {
    logoutUser()
  }
})
