import Vue from 'vue'
import Vuex from 'vuex'
import { createStore } from 'vuex-extensions'
import moment from 'moment'
import controller from '../helper/controller'

Vue.use(Vuex)

export default createStore(Vuex.Store, {
  state: {
    language: localStorage.getItem('language') || 'en',
    base_currency: localStorage.getItem('currency') || 'CAD',
    currency_data: {},
    location: {
      address: '',
      city: localStorage.getItem('city') ?? '',
      region_code: localStorage.getItem('region_code'),
      coordinates: {
        lat: localStorage.getItem('lat'),
        lng: localStorage.getItem('lng'),
      },
      weather: Math.round(Math.random()) ? 'Clear' : 'Cloud',
    },
    account: {
      authorized: false,
      userID: '',
      email: '', // signup username
      username: '', // display username
    },
    profile: {
      subscribed: false,
      display_name: '', // display username
      email: '', // newsletter email
    },
    properties: [],
    propertyID: '',
    property: {
      photo: '',
      street_number: 0,
      street_name: '',
      city: '',
      price: 0,
      rent: 0,
      rent_this_year: 0,
      other_income: 0,
      down_payment: 0,
      appreciation: 0,
      mortgage_principal: 0,
      mortgage_interest: 0,
      remaining_principal: 0,
      principal_repayment: 0,
      monthly_expense: 0,
      yearly_expense: 0,
      rental_gain_or_lose: 0,
      mortgage_gain_or_lose: 0,
      gain_or_lose: 0
    },
    utilities: [],
    utility: {},
    expenses: [],
    all_expenses: {},
    invoices: [],
    thisMonthExpense: {
      electricity: 0,
      water: 0,
      internet: 0,
      boiler: 0,
      gas: 0
    },
    lastMonthExpense: {
      electricity: 0,
      water: 0,
      internet: 0,
      boiler: 0,
      gas: 0
    },
    tenants: [],
    tenantID: '',
    tenant: {},
    file_url: '',
    errors: [],
    notifications: [],
    agreements: {},
    currentAgreementId: '',
    agreementPageReady: false,
  },
  getters: {
    language: state => {
      return state.language
    },
    base_currency: state => {
      return state.base_currency
    },
    currency_data: state => {
      return state.currency_data
    },
    authorized: state => {
      return state.account.authorized
    },
    email: state => {
      return state.account.email ? state.account.email : state.profile.email
    },
    username: state => {
      return (
        state.account.username ||
        state.profile.display_name ||
        state.account.email
      )
    },
    account: state => {
      return state.account
    },
    profile: state => {
      return state.profile
    },
    subscribed: state => {
      return state.profile.subscribed
    },
    location: state => {
      return state.location
    },
    address: state => {
      return state.location.address
    },
    city: state => {
      return state.location.city
    },
    region_code: state => {
      return state.location.region_code
    },
    coordinates: state => {
      return state.location.coordinates
    },
    lat: state => {
      return state.location.coordinates.lat
    },
    lng: state => {
      return state.location.coordinates.lng
    },
    weather: state => {
      return state.location.weather
    },
    propertyID: state => {
      return state.propertyID
    },
    properties: state => {
      return state.properties
    },
    property: state => {
      return state.property
    },
    utilities: state => {
      return state.utilities
    },
    utility: state => {
      return state.utility
    },
    expenses: state => {
      return state.expenses
    },
    all_expenses: state => {
      return state.all_expenses
    },
    invoices: state => {
      return state.invoices
    },
    thisMonthExpense: state => {
      return state.thisMonthExpense
    },
    lastMonthExpense: state => {
      if (state?.lastMonthExpense?.date) {
        return state.lastMonthExpense
      } else {
        return state.thisMonthExpense
      }
    },
    tenantID: state => {
      return state.tenantID
    },
    tenants: state => {
      return state.tenants
    },
    tenant: state => {
      return state.tenant
    },
    file_url: state => {
      return state.file_url
    },
    errors: state => {
      return state.errors
    },
    notifications: state => state.notifications,
    agreements: state => state.agreements,
    currentAgreementId: state =>  state.currentAgreementId,
    agreementPageReady: state => state.agreementPageReady,
  },
  mutations: {
    set_language(state, language) {
      state.language = language
      localStorage.setItem('language', language)
    },
    set_currency(state, new_currency) {
      state.base_currency = new_currency
      localStorage.setItem('currency', new_currency)
    },
    set_currency_data(state, new_currency_data) {
      Vue.set(state, 'currency_data', { ...new_currency_data })
    },
    set_authorized(state, authorized) {
      state.account.authorized = authorized
    },
    login(state, account) {
      state.account.authorized = true
      state.account.email =
        (account?.email?.indexOf('@') > 0 ? account?.email?.toLowerCase() : '')
        || (account?.username?.indexOf('@') > 0 ? account?.username?.toLowerCase() : '')
      state.account.userID = account.userID || account.id || state.account.email
      localStorage.setItem('SSO', 'enabled')
      localStorage.setItem('authorized', true)
    },
    logout(state) {
      state.account.authorized = false
      localStorage.removeItem("SSO");
      localStorage.removeItem('authorized');
      this.reset();
      controller.goto_page('Auth')
    },
    set_username(state, username) {
      state.account.username = username
      state.profile.display_name = username
      if (state.property) {
        state.property.owner = username
      }
      if (state.tenant) {
        state.tenant.landlord = username
      }
    },
    set_profile(state, profile) {
      Vue.set(state, 'profile', { ...profile })
    },
    set_email(state, email) {
      if (email && email.indexOf('@') > 0 && email.indexOf('.') > 0) {
        state.profile.email = email.trim().toLowerCase()
      }
    },
    set_subscribed(state, subscribed) {
      state.profile.subscribed = subscribed
    },
    set_default_location(state, address) {
      state.property.address = address;
      state.location.address ||= address; // set default user location to current property address
    },
    set_address(state, address) {
      state.location.address = address
    },
    set_property_address(state, address) {
      state.property.address = address
      state.location.address ||= address
    },
    set_city(state, city) {
      state.location.city = city
      localStorage.setItem('city', city)
    },
    reset_errors(state) {
      state.errors = []
    },
    push_error(state, error) {
      if (error.message) {
        state.errors.push(error)
      }
    },
    set_file_url(state, file_url) {
      state.file_url = file_url
    },
    reset_file_url(state) {
      state.file_url = ''
    },
    set_properties(state, properties) {
      properties = properties.sort(
        (p1, p2) => moment(p2.updatedAt) - moment(p1.updatedAt)
      )
      Vue.set(state, 'properties', [...properties])
    },
    pick_property_id(state, propertyID) {
      if (propertyID) {
        state.propertyID = propertyID
      } else {
        return
      }

      if (
        state.properties?.length > 0 &&
        state.property?._id?.toString() !== propertyID
      ) {
        state.property = state.properties.find(
          p => p._id?.toString() === propertyID
        )

        if (!state.property) {
          controller.load_property(propertyID)
        }
      } else if (state.properties?.length == 0) {
        controller.load_property(propertyID)
      } else {
        // do nothing
      }
    },
    push_new_property(state, property) {
      state.properties.push({ ...property })
    },
    delete_property(state, propertyID) {
      state.properties = state.properties.filter(
        p => p._id?.toString() !== propertyID
      )
    },
    set_property(state, property) {
      state.propertyID = property._id?.toString()
      Vue.set(state, 'property', { ...property })

      if (state.properties?.length == 0) {
        state.properties.push({ ...property })
      } else {
        let properties = state.properties.filter(
          p => p._id?.toString() !== property._id?.toString()
        )
        properties.push(property)

        properties = properties.sort(
          (p1, p2) => moment(p2.updatedAt) - moment(p1.updatedAt)
        )
        Vue.set(state, 'properties', [...properties])
      }
    },
    clear_expenses(state) {
      Vue.set(state, 'expenses', []);
    },
    set_expenses(state, expenses) {
      expenses = expenses.sort((e1, e2) => moment(e1.date, 'YYYY/MM') - moment(e2.date, 'YYYY/MM'))
      Vue.set(state, 'expenses', [...expenses]);

      let thisMonthExpense = expenses[expenses.length - 1]
      Vue.set(state, 'thisMonthExpense', { ...thisMonthExpense })
    },
    set_all_expenses(state, all_expenses) {
      Vue.set(state, 'all_expenses', all_expenses);
    },
    update_all_expenses(state, { property_id, new_expenses}) {
      state.all_expenses[property_id] = new_expenses
    },
    clear_thisMonthExpense(state,) {
      Vue.set(state, 'thisMonthExpense', {})
    },
    set_thisMonthExpense(state, thisMonthExpense) {
      if (thisMonthExpense?.property && thisMonthExpense?.property === state.propertyID) {
        Vue.set(state, 'thisMonthExpense', { ...thisMonthExpense })

        state.expenses = state.expenses.map(expense => {
          if (expense.property === state.thisMonthExpense.property
            && expense.date === state.thisMonthExpense.date) {
            return {...state.thisMonthExpense}
          }
          else {
            return expense
          }
        }, state)

        state.all_expenses[state.thisMonthExpense?.property] = state.expenses
      }
    },
    clear_lastMonthExpense(state) {
      Vue.set(state, 'lastMonthExpense', {})
    },
    set_lastMonthExpense(state, lastMonthExpense) {
      Vue.set(state, 'lastMonthExpense', { ...lastMonthExpense })
    },
    set_invoices(state, invoices) {
      Vue.set(state, 'invoices', [...invoices])
    },
    set_utility(state, utility) {
      delete utility.private_key // #important: Keep private_key on the server

      if (utility.property_id !== state.propertyID) {
        var utl = state.utilities.find(
          u => u.property_id === utility.property_id
        )
        if (utl) {
          state.utility = utl
        } else {
          Vue.set(state, 'utility', { ...utility })
          state.utilities.push(state.utility)
        }
      } else {
        Vue.set(state, 'utility', { ...utility })
      }
    },
    add_set_tenants(state, tenants) {
      var tenants_copy = [...tenants.filter(t => t.rent > 0 || t.deposit > 0)]

      tenants_copy = tenants_copy.sort(
        (t1, t2) => moment(t2.updatedAt) - moment(t1.updatedAt)
      )
      Vue.set(state, 'tenants', tenants_copy)
    },
    pick_tenant_id(state, tenantID) {
      if (tenantID) {
        state.tenantID = tenantID
      } else {
        return
      }

      if (
        state.tenants?.length > 0 &&
        state.tenant?._id?.toString() !== tenantID
      ) {
        state.tenant = state.tenants.find(t => t._id?.toString() === tenantID)

        if (!state.tenant) {
          controller.load_tenant(tenantID)
        }
      } else if (state.tenants?.length == 0) {
        controller.load_tenant(tenantID)
      } else {
        // do nothing
      }
    },
    push_new_tenant(state, tenant) {
      state.tenants.push({ ...tenant })
    },
    delete_tenant(state, tenantID) {
      state.tenants = state.tenants.filter(t => t._id?.toString() !== tenantID.toString())
    },
    add_set_tenant(state, tenant) {
      state.tenantID = tenant._id?.toString()
      Vue.set(state, 'tenant', { ...tenant })

      if (state.tenants?.length == 0) {
        state.tenants.push({ ...tenant })
      } else {
        let tenants = state.tenants.filter(t => t.rent > 0 || t.deposit > 0)
        tenants = tenants.filter(
          t => t._id?.toString() !== tenant._id?.toString()
        )
        tenants.push(tenant)

        tenants = tenants.sort(
          (t1, t2) => moment(t2.updatedAt) - moment(t1.updatedAt)
        )
        Vue.set(state, 'tenants', [...tenants])
      }
    },
    set_tenant(state, tenant) {
      state.tenant = tenant;
      state.tenantID = tenant._id?.toString();
    },
    set_notifications(state, notifications) {
      state.notifications = notifications
    },
    set_agreements(state, agreements) {
      state.agreements = agreements;
    },
    add_update_agreement(state, agreement) {
      state.agreements = {
        ...state.agreements,
        [agreement._id]: agreement
      }
    },
    set_currentAgreementId(state, agreementId) {
      state.currentAgreementId = agreementId;
    },
    set_agreementPageReady(state, status) {
      state.agreementPageReady = status
    },
    read_notification(state, id) {
      state.notifications.forEach(not => {
        if (not._id === id && not.read === false) {
          not.read = true;
          return
        }
      });
    },
    delete_notification(state, id) {
      state.notifications = state.notifications.filter(not => not._id !== id);
    },
  },
  actions: {},
})
