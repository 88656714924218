import aws_lambda from './aws_lambda';
import router from "../router";
import store from "../store";
import api_handle, { external_api } from "./api_handle";
import { trigger_gtag_lease_document, trigger_gtag_dashboard } from "../helper/gtag_controller";
import calendar from "./calendar";
import calculator from "./calculator";
import i18next from 'i18next';
import moment from "moment";


const {
  total_property_monthly_rent, total_property_monthly_expense,
  this_year_property_fixed_expense, total_tenant_owing_today
} = calculator
const {
  formatDate, get_today, get_year_from_now, is_later,
  get_year_and_month, get_last_month, begin_of_next_cycle,
  end_of_next_cycle, get_next_due_date
} = calendar
const UNAUTHORIZED = 'unauthorized';


export const DASHBOARD_TAB_DEFAULT = 2
export const DASHBOARD_TAB_TOTAL = 10
export const DASHBOARD_TAB_INDEX = [
  'Home', // 0
  'Dashboard', // 1
  'PropertyList', // 2
  'Rentals', // 3
  'Utilities', // 4
  'Maintenance', // 5
  'Financials', // 6
  'Insurance', // 7
  '', // 8
  'Settings', // 9
  'Logout', // 10
]



export const validate_result = (result) => {
  if (result.error && result.error === UNAUTHORIZED) {
    goto_page('Logout');
    return false;
  }
  else if (result.error) {
    throw new Error(i18next.t(`error.${result.error}`));
  }
  else {
    return true
  }
}

const check_error_for_authorization = (error) => {
  if (error.request?.responseURL?.indexOf('/api/newsletter') > -1) {
    return
  }

  if (error.response && (error.response.status == 401 || error.response.status == 404)) {
    goto_page('Logout');
  }
  else {
    store.commit('push_error', error)
  }
}



let default_profile_name = store.state.profile.display_name || store.state.account.username
let default_profile_email = store.state.profile.email || store.state.account.email
let default_profile_city = store.state.location.city
export const new_profile = (profile_name = default_profile_name, profile_email = default_profile_email, profile_city = default_profile_city) => {
  api_handle
    .post('/profile', {
      name: profile_name,
      email: profile_email,
      city: profile_city
    })
    .then(response => {
      validate_result(response.data);

      if (response.data.data.profile) {
        let profile = response.data.data.profile
        store.commit('set_profile', profile);
        store.commit('set_username', profile.display_name);
      }
    })
    .catch(e => {
      check_error_for_authorization(e)
    });
}


export const load_username = () => {
  api_handle.get('/profile/name')
    .then(response => {
      validate_result(response.data);
      if (response.data.data.display_name) {
        store.commit('set_username', response.data.data.display_name)
      }
    })
    .catch(e => {
      check_error_for_authorization(e);
    });
}
export const load_profile = () => {
  api_handle.get('/profile')
    .then(response => {
      validate_result(response.data);

      if (response.data.data.profile) {
        var profile = response.data.data.profile
        if (!profile?.email) {
          profile.email = store.state.profile?.email || store.state.account.email
        }
        if (!profile.city && store.state.location.city) {
          profile.city = store.state.location.city
        }

        store.commit('set_profile', profile);
        store.commit('set_username', profile.display_name);
      }
      else {
        new_profile();
      }
    })
    .catch(e => {
      check_error_for_authorization(e);
    });
}
export const send_profile = (profile_data) => {
  api_handle.put("/profile", profile_data)
    .then(response => {
      validate_result(response.data);

      if (response.data.data.profile) {
        let profile = response.data.data.profile
        store.commit('set_profile', profile);
        store.commit('set_username', profile.display_name);
      }
    })
    .catch(e => {
      check_error_for_authorization(e);
    });
}


const get_property_address = (property) => {
  return `${property?.street_number} ${property?.street_name}${property?.city ? `, ${property?.city}` : ''}${property?.postal_code ? `, ${property?.postal_code}` : ''}${property.suite_number ? ` (${property.suite_number})` : ''}`
}


export const handle_property_record = (property) => {
  if (!property.address || property.address.indexOf('undefined') > 0 || property.address.indexOf(',') < 0) {
    if (property?.street_number && property?.street_name) {
      property.address = get_property_address(property)
    }
  }

  if (property.move_in_date) {
    property.move_in_date = formatDate(property.move_in_date)
  }
  else {
    property.move_in_date = get_today()
  }

  property.rent = total_property_monthly_rent(property._id) || property.rent
  property.rent_this_year ||= property.rent * 12

  property.vacancy = property.vacancy || 0
  property.occupancy = property.bedroom_count - property.vacancy
}
export const update_property_vuex_store = (property) => {
  if (store.state.properties?.length == 0) {
    store.state.properties.push({ ...property })
  }
  else {
    const properties = store.state.properties.filter((p) => p._id?.toString() !== property._id?.toString())

    properties.push(property)
    store.commit('set_properties', [...properties]);
  }
}


export const load_properties = async () => {
  const all_expenses = {};

  if (store.state.properties?.length > 0) {
    return true
  }
  else {
    try {
      const res = await api_handle.get("/property/list")
      if (res.data.code === 0 && res.data.data.length > 0) {
        let properties = res.data.data
        await Promise.all([
          ...properties.map((property) => handle_property_record(property)),
          ...properties.map(async (property) => {
            all_expenses[property._id] = (await load_expenses(property._id, false)) || []
            return all_expenses
          }),
        ]);

        store.commit('set_all_expenses', all_expenses);
        
        store.commit('set_properties', properties)
        if (!store.state.propertyID) {
          store.commit('pick_property_id', properties[0]._id)
        }
      
        return true
      } else {
        return false
      }
    } catch (error) {
      return false
    }
  }
}


export const new_property = (property_data) => {
  handle_property_record(property_data)

  api_handle.post('/property', property_data)
    .then((response) => {
      if (response.data.code === 0 && response.data.property?._id) {
        let property = response.data.data
        handle_property_record(property)
        store.commit('push_new_property', property)
      }
    })
    .catch((e) => {
      store.commit("push_error", e);
    });
}


export const load_property_address = (propertyID) => {
  if (propertyID) {
    api_handle.get(`/property/address/${propertyID}`)
      .then(response => {
        if (response.data.code === 0) {
          store.commit("set_property_address", response.data.data);
        }
      })
      .catch(e => {
        check_error_for_authorization(e)
      })
  }
}


export const load_property = (propertyID) => {
  if (!propertyID) return

  let properties = store.state.properties
  if (store.state.propertyID === propertyID && store.state.property?._id?.toString() === propertyID) {
    return
  }
  else if (properties?.length > 0 && properties.find(p => p._id?.toString() === propertyID)) {
    let property = properties.find(p => p._id?.toString() === propertyID)

    store.commit("set_property", property);
    if (property.address) {
      store.commit("set_default_location", property.address);
    }
  }
  else {
    api_handle.get(`/property/${propertyID}`)
      .then(response => {
        if (response.data.code === 0) {
          let property = response.data.data
          handle_property_record(property)

          store.commit("set_property", property);
          if (property.address) {
            store.commit("set_default_location", property.address);
          }
        }
      })
      .catch(e => {
        check_error_for_authorization(e)
      })
  }
}


export const send_property_photo = (propertyID, photo_url) => {
  if (propertyID && photo_url) {
    api_handle
      .put(`/property/photo`, {
        id: propertyID,
        photo: photo_url,
      })
      .then((response) => {
        if (response.data.code === 0 && response.data.property?._id) {
          let property = response.data.data
          handle_property_record(property)
          // update data store with new property record
          let properties = store.state.properties
          properties = properties.filter(p => p._id?.toString() !== propertyID)

          properties.push(property)
          store.commit('set_properties', [...properties])
        }
      })
      .catch((e) => {
        store.commit("push_error", e);
      });
  }
}

export const send_property = (property_data, setStore = true) => {
  if (property_data._id) {
    api_handle.put(`/property/${property_data._id}`, property_data)
      .then(response => {
        if (response.data.code === 0 && response.data.data?._id) {
          let property = response.data.data
          handle_property_record(property)

          // update data store with new property record
          setStore && store.commit('set_property', { ...property });

          // let properties = store.state.properties
          // properties = properties.filter(p => p._id?.toString() !== property_data._id?.toString())
          // properties.push(property)
          // properties = properties.sort((p1, p2) => moment(p2.updatedAt) - moment(p1.updatedAt))
          // store.commit('set_properties', [...properties])
        }
      })
      .catch(e => {
        check_error_for_authorization(e)
      })
  }
}


const handle_tenant_record = (tenant, is_require_update = false) => {
  if (typeof tenant === 'undefined' || !tenant) return

  if (!tenant?.landlord) {
    tenant.landlord = store.state.profile.display_name || store.state.account.username
  }

  if (!tenant.address || tenant.address.indexOf('undefined') > 0 || tenant.address.indexOf(',') < 0) {
    const property = store.state.properties.find(p => p._id?.toString() === tenant.property)
    if (property?.street_number && property?.street_name) {
      tenant.address = get_property_address(property)
    }
  }

  if (tenant.rental_start_date) {
    tenant.rental_start_date = formatDate(tenant.rental_start_date)
  }

  if (tenant.rental_end_date) {
    tenant.rental_end_date = formatDate(tenant.rental_end_date)
  }
  else if (!tenant.rental_end_date && tenant.rent == 0) {
    tenant.rental_end_date = formatDate(get_today())
  }
  else {
    tenant.rental_end_date = formatDate(get_year_from_now())
  }

  if (tenant.last_reminder_sent_date && !is_later(tenant.last_reminder_sent_date)) {
    tenant.last_reminder_sent_date = formatDate(tenant.last_reminder_sent_date)
  }

  tenant.next_due_date = get_next_due_date(tenant)

  tenant.begin_of_next_cycle = begin_of_next_cycle(tenant);
  tenant.end_of_next_cycle = end_of_next_cycle(tenant);

  tenant.owing = total_tenant_owing_today(tenant);

  if (is_require_update) {
    send_tenant(tenant)
  }

  /*
  // IMPORTANT: enable for removing test tenant records during debug only
  if (tenant.rent == 0 || tenant.name?.toLowerCase().indexOf('test') >= 0) {
    api_handle.delete(`/tenant/` + tenant._id)
      .catch(e => {
        check_error_for_authorization(e)
      })
  }
  */
}

export const update_tenant_vuex_store = (tenant) => {
  let tenants = store.state.tenants.filter(t => t.rent > 0 || t.deposit > 0)
  tenants = tenants.filter(t => t._id?.toString() !== tenant._id?.toString())

  tenants.push(tenant)
  store.commit('add_set_tenants', [...tenants]);
}


export const load_tenants = async () => {
  if (store.state.tenants?.length > 0) {
    return
  }
  else {
    api_handle.get('/tenant')
      .then(response => {
        validate_result(response.data);

        if (response.data.tenants?.length > 0) {
          let tenants = response.data.tenants.filter(t => t.rent > 0 || t.deposit > 0)
          // tenants = tenants.sort((t1, t2) => moment(t2.updatedAt) - moment(t1.updatedAt))

          tenants.forEach(function (tenant) {
            handle_tenant_record(tenant, true)
            // tenant.is_reminder_sent = false // IMPORTANT: enable for testing submit_rent_reminder only
          })

          store.commit('add_set_tenants', [...tenants])
          if (!store.state.tenantID) {
            store.commit('pick_tenant_id', response.data.tenants[0]._id)
          }
        }
      })
      .catch(e => {
        check_error_for_authorization(e);
      });
  }
}

export const new_tenant = async (tenant_data) => {
  handle_tenant_record(tenant_data, false)
  if (!tenant_data.paid) {
    tenant_data.paid = tenant_data.deposit
  }
  const response = await api_handle.post('/tenant', tenant_data)
  if (!response.data.tenant?._id) {
    return false
  }
  let tenant = response.data.tenant
  handle_tenant_record(tenant, true)

  store.commit('push_new_tenant', tenant)

  // update data store with new tenant record
  let tenants = store.state.tenants
  tenants = tenants.filter(t => t._id?.toString() !== tenant._id?.toString())
  tenants.push(tenant)
  store.commit('add_set_tenants', [...tenants])
  return tenant
}


export const load_tenant = (tenantID) => {
  let tenants = store.state.tenants
  if (store.state.tenantID === tenantID && store.state.tenant?._id?.toString() === tenantID) {
    return
  }
  else if (tenants?.length > 0 && tenants.find(t => t._id?.toString() === tenantID)) {
    let tenant = tenants.find(t => t._id?.toString() === tenantID)
    store.commit('add_set_tenant', tenant);
  }
  else {
    api_handle.get(`/tenant/${tenantID.toString()}`)
      .then(response => {
        validate_result(response.data);

        if (response.data.tenant?._id) {
          let tenant = response.data.tenant
          handle_tenant_record(tenant, true)

          // update data store with new tenant record
          store.commit('add_set_tenant', tenant);
        }
      })
      .catch(e => {
        check_error_for_authorization(e)
      })
  }
}


export const send_tenant = async (tenant_data) => {
  try {
    tenant_data.owing = total_tenant_owing_today(tenant_data);
    const res = await api_handle.put(`/tenant/${tenant_data._id}`, tenant_data)
    if (res.data?.tenant?._id) {
      let tenant = res.data.tenant
      handle_tenant_record(tenant, false)
      // update data store with new tenant record
      store.commit('add_set_tenant', { ...tenant });
      return res.data.tenant
    }
    return null
  } catch (error) {
    check_error_for_authorization(error)
    return null;
  }
}


export const remove_tenant = (tenantID) => {
  const tenant = store.state.tenants.find(t => t._id?.toString() === tenantID)
  if (!tenant.deposit) {
    api_handle.delete(`/tenant/${tenantID}`)
      .then(response => {
        validate_result(response.data);

        store.commit('delete_tenant', tenantID)
        goto_page('Rentals')
      })
      .catch(e => {
        check_error_for_authorization(e);
      })
  }
  else {
    alert(`There is a deposit of ${tenant.deposit} yet to be return to the tenant. Please refund the deposit and clear the number.`)
  }
}


export const submit_rent_reminder = (tenantID) => {
  api_handle.post(
    '/tenant/submit_rent_reminder',
    { tenantID: tenantID },
  ).then(response => {
    validate_result(response.data);
  }).catch(e => {
    check_error_for_authorization(e)
  });
}
export const confirm_rent_payment = (tenant_data, payment = tenant_data.rent) => {
  api_handle.put(
    '/tenant/confirm_rent_payment',
    {
      id: tenant_data._id,
      payment: payment,
      currency: 'CAD',
      next_due_date: get_next_due_date(tenant_data),
      begin_of_next_cycle: begin_of_next_cycle(tenant_data),
      end_of_next_cycle: end_of_next_cycle(tenant_data),
    },
  ).then(response => {
    validate_result(response.data);
    tenant_data.paid += payment;
    tenant_data.owing -= payment;
    tenant_data.next_due_date = get_next_due_date(tenant_data);
    tenant_data.begin_of_next_cycle = begin_of_next_cycle(tenant_data);
    tenant_data.end_of_next_cycle = end_of_next_cycle(tenant_data);
    open_page('RentBalance', { id: tenant_data._id });
  }).catch(e => {
    check_error_for_authorization(e)
  });
}


export const handle_expense_record = async (expense, setStore = true) => {

  const property = store.state.properties.find(p => p._id?.toString() === expense.property)
  if (property) {
    expense.address = property.address
    if (expense.date === get_year_and_month()) {
      let thisMonthExpense = expense
      if ((Number(property.monthly_expense) !== Number(thisMonthExpense.monthly_total))
        || (Number(property.yearly_expense) !== Number(thisMonthExpense.yearly_total))) {
        property.monthly_expense = thisMonthExpense.monthly_total
        property.yearly_expense = thisMonthExpense.yearly_total

        if (property.monthly_total > 0) {
          await send_property({ ...property }, setStore)
        }
      }
    }
  }
}

export const handle_expenses_result = async (expenses, setStore = true) => {
  if (!expenses?.length) return;

  // check existing expense records from the database
  let lastMonthExpense = expenses.find(expense => expense.date === get_last_month())
  let thisMonthExpense = expenses.find(expense => expense.date === get_year_and_month())

  // if there is lastMonthExpense but thisMonthExpense
  if (lastMonthExpense?.date && !thisMonthExpense?.date) {
    thisMonthExpense = { ...lastMonthExpense }
    delete thisMonthExpense._id

    thisMonthExpense.date = get_year_and_month()
    await send_expense(thisMonthExpense, setStore)
  }
  // if there is no lastMonthExpense neither thisMonthExpense
  else if (!thisMonthExpense?.date) {
    expenses = expenses.sort((e1, e2) => moment(e2.date, 'YYYY/MM') - moment(e1.date, 'YYYY/MM'))

    thisMonthExpense = { ...expenses[0] }
    delete thisMonthExpense._id
    thisMonthExpense.date = get_year_and_month()

    lastMonthExpense = { ...expenses[0] }
    delete lastMonthExpense._id
    lastMonthExpense.date = get_last_month()

    Promise.all([
      send_expense(lastMonthExpense, setStore),
      send_expense(thisMonthExpense, setStore)
    ])
  }
  else if (lastMonthExpense?.date === thisMonthExpense?.date) {
    if (lastMonthExpense._id === thisMonthExpense._id) {
      delete thisMonthExpense._id
      delete lastMonthExpense._id
    }

    lastMonthExpense.date = get_last_month()
    thisMonthExpense.date = get_year_and_month()

    Promise.all([
      send_expense(lastMonthExpense, setStore),
      send_expense(thisMonthExpense, setStore)
    ])
  }

  // if no record found, show the dummy data
  if (!thisMonthExpense?.monthly_total || !lastMonthExpense?.monthly_total) return;

  setStore && store.commit('set_thisMonthExpense', thisMonthExpense)
  setStore && store.commit('set_lastMonthExpense', lastMonthExpense)
}

export const total_yearly_expense_by_ID = async (propertyID, setStore = true) => {
  let expenses = await load_expenses(propertyID, setStore)
  if (expenses?.length > 0) {
    return calculator.total_property_yearly_generalized_expense(
      expenses
    );
  }
  else {
    return 0
  }
}

export const load_expenses = (propertyID, setStore = true) => {
  if (store.state.all_expenses.length > 0 && store.state.all_expenses[propertyID].length > 0) {
    return store.state.all_expenses[propertyID]
  }

  if (typeof(propertyID) ==='undefined' || !propertyID) {
    return
  }

  return api_handle.get(`/expense?property=${propertyID}`)
    .then(response => {
      validate_result(response.data);
      let expenses = response.data.data.expenses

      if (expenses?.length > 0 && expenses[0].property.toString() === propertyID) {
        // expenses = expenses.sort((e1, e2) => moment(e1.date) - moment(e2.date))

        expenses.forEach(function (expense) {
          handle_expense_record(expense, setStore)
        })
        handle_expenses_result(expenses, setStore)

        setStore && store.commit('set_expenses', expenses)
        setStore && store.commit('update_all_expenses', { propertyID, new_expenses: expenses })

        const date = get_year_and_month();
        let thisMonthExpense = expenses.find(e => e.date === date)
        if (!thisMonthExpense) {
          thisMonthExpense = {property: propertyID, date: date}
        }
        setStore && store.commit('set_thisMonthExpense', thisMonthExpense)

        return expenses
      }
      else {
        let thisMonthExpense = {property: propertyID, date: get_year_and_month()}
        setStore && store.commit('set_thisMonthExpense', thisMonthExpense)

        let expenses = [thisMonthExpense]
        setStore && store.commit('set_expenses', expenses)

        handle_expenses_result(expenses, setStore)
        return expenses
      }
    }).catch(e => {
      check_error_for_authorization(e)
    });
}


export const send_expense = async (expense, setStore = true) => {
  if (!expense.date) {
    expense.date = get_year_and_month();
  }
  expense.monthly_total = total_property_monthly_expense(expense)
  expense.yearly_total = this_year_property_fixed_expense(expense)

  if (expense.monthly_total > 0) {
    return api_handle.put('/expense', expense)
      .then(response => {
        validate_result(response.data);

        if (response.data.data.expense) {
          setStore && store.commit('set_thisMonthExpense', expense)
        }

        return response.data
      })
      .catch(e => {
        check_error_for_authorization(e)
      });
  }
}



/**
 * Go to a route
 * @param {string} page_name
 * @param {object={}} query
 * @param {object={}} params
 */
export const goto_page = (page_name, query = {}, params = {}) => {
  if (page_name) {
    trigger_gtag_lease_document(window?.gtag, page_name, params?.id)
    trigger_gtag_dashboard(window?.gtag, page_name, router.currentRoute.path)
    router.push({ name: page_name, query, params }).catch(() => { })
  }
}

/**
 * Open an external link
 * @param {string} page_name
 * @param {object={}} query
 */
export const open_page = (page_name, query = {}) => {
  if (page_name) {
    const page_link = router.resolve({ name: page_name, query }).href
    window.open(page_link, '_antslabour')
  }
}
/**
 * Open an external link
 * @param {string} page_link
 */
export const open_link = (page_link) => {
  if (page_link) {
    window.open(page_link, '_blank', 'noopener,noreferrer')
  }
}

export const history_page = () => {
  router.go(-1).catch(() => { })
}

export const refresh_page = () => {
  router.go(0).catch(() => { })
}


export const bind_back_button_on_nav_menu_tab = (load_list) => {
  const tab = document.querySelector('.menu-item.active')
  if (tab && load_list) {
    tab.onclick = function () {
      load_list();
    }
  }
}

const compress_image = (file, imageWidth = 300, quality = 0.8) => {
  return new Promise((res) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onload = (evt) => {
      const img = new Image();
      img.src = evt.target.result;

      img.onload = (event) => {
        const canvas = document.createElement('canvas');
        const MAX_WIDTH = event.target.width > imageWidth ? imageWidth : event.target.width;
        const scaleSize = MAX_WIDTH / event.target.width;
        canvas.width = MAX_WIDTH;
        canvas.height = event.target.height * scaleSize;
        const ctx = canvas.getContext('2d');
        ctx.drawImage(event.target, 0, 0, canvas.width, canvas.height);
        ctx.canvas.toBlob(res, 'image/webp', quality)
      };
    }
  })
}

export const upload_property_img = async (file, imageWidth, quality) => {
  const newFile = await compress_image(file, imageWidth, quality);
  const form_data = new FormData();
  form_data.append('file', newFile)

  return external_api.post(aws_lambda.UPLOAD_URL, form_data).then((res) => {
    return res?.data?.url;
  }).catch((e) => {
    // eslint-disable-next-line no-console
    console.error(e)
  })
}

export const load_user_notifications = async () => {
  const res = await api_handle.get('/notification')

  if (res.data.code === 0) {
    const data = res.data.data.map(not => ({
      ...not,
      date: formatDate(new Date(not.date)),
    }));

    store.commit('set_notifications', data)
  }
}

export const read_user_notification = async (id) => {
  const res = await api_handle.put(`/notification/read/${id}`);

  if (res.data.code === 0) {
    return true;
  }
  return false;
};

export const del_user_notification = async (id) => {
  const res = await api_handle.delete(`/notification/${id}`);

  if (res.data.code === 0) {
    return true;
  }
  return false
}

export const getCookie = (name) => {
  let nameEQ = name + "=";
  let cookies = document.cookie.split(';');

  for (let i = 0; i < cookies.length; i++) {
    let c = cookies[i];

    while (c.charAt(0) == ' ') {
      c = c.substring(1, c.length);
    }

    if (c.indexOf(nameEQ) == 0) {
      return decodeURIComponent(c.substring(nameEQ.length, c.length));
    }
  }
  return null;
}

export const stripHtml = (htmlString) => {
  let tmp = document.createElement("DIV");
  tmp.innerHTML = htmlString;
  return tmp.textContent || tmp.innerText || "";
}

export const getAuthorizedUser = async () => {
  try {
    const res = await api_handle.get('/auth/user')

    if (res.data.code === 0 && res.data.data) {
      const user = res.data.data;
      store.commit('login', user)
    }
  } catch (error) {
    // goto_page('Auth');
    localStorage.setItem('authorized', false)
  }
}

export const logoutUser = async () => {
  try {
    const res = await api_handle.get('/auth/logout')

    if (res.data.code === 0) {
      store.commit('logout')
    }
  } catch (error) {
    goto_page('Auth');
  }
}

export const getAgreementTemplate = () => ({
  _id: '',
  type:  '',
  water_handler: '',
  heat_handler: '',
  electricity_handler: '',
  base_rent: 0,
  landlord_id: '',
  landlord_name: '',
  landlord_email: '',
  landlord_address: '',
  landlord_postal_code: '',
  landlord_city: '',
  landlord_province: '',
  landlord_phone: '',
  landlord_po_box: '',
  landlord_contacts: '',
  landlord_unit: '',
  tenant_id: '',
  tenant_name: '',
  tenant_mailing_address: '',
  tenant_email: '',
  tenant_phone: '',
  tenant_address: '',
  tenant_nok_name: '',
  tenant_nok_phone: '',
  is_smoking_allowed: false,
  smoking_rules: '',
  is_tenant_insured: false,
  occupants: [],
    
  property_unit: '',
  property_id: '',
  property_type: 'House',
  property_address: '',
  property_city: '',
  property_province: '',
  property_vehicle_space: 0,
  property_postal_code: '',
  manager_name: '',
  manager_address: '',
  manager_email: '',
  manager_phone: '',
  superintendent_name: '',
  superintendent_address: '',
  superintendent_email: '',
  superintendent_phone: '',
  notice_to_terminate: [
    'landlord'
  ],
  start_date: get_today(),
  end_date: '',
  partial_rent_amount: '',
  partial_rent_payment_date: '',
  partial_rent_start_date: '',
  partial_rent_end_date: '',
  tenant_nsf_additonal: 0,
  rent_amount: 0,
  other_rent_payments: {
    'Parking (if applicable)': 0
  },
  rent_payment_currency: 'CAD',
  rent_cycle: 'Month',
  rent_payment_method: 'Cash',
  rent_payment_day: 1,
  rent_autoChange: false,
  rent_payable_to: '',
  rent_discount: false,
  rent_discount_description: '',
  included_items: {},
  included_details: '',
  tenant_responsibilities: [],
  tenant_responsibilities_details: '',
  key_deposit_amount: 0,
  key_deposit_details: '',
  deposit: 0,
  deposit_currency: '',
  is_property_inspected: false,
  signatures: [],
  additional_terms: [],
  landlord_signature: '',
  landlord_signature_date: '',
  tenant_signature: '',
  tenant_signature_date: '',
});

export const createOrUpdateAgreement = async (data) => {
  Object.keys(data).forEach(key => {
    if (data[key] === '' || data[key] === null || data[key] === undefined) {
      delete data[key]
    }
  });
  
  try {
    const res = await api_handle[data._id ? 'put' : 'post']('/agreement', data);
    if (res?.data.code === 0) {
      const agreement = res.data.data;
      store.commit('add_update_agreement', { ...getAgreementTemplate(),  ...agreement });
      store.commit('set_currentAgreementId', agreement._id);
    } 

    return res?.data;
  } catch (error) {
    return false
  }
}

export const load_agreements = async () => {
  if (store.state.agreements?.length > 0) {
    return
  }

  try {
    const res = await api_handle.get('/agreement');

    if (res.data.code === 0) {
      const agreements = {};
      res.data.data.agreements.forEach(agreement => {
        agreements[agreement._id] = { ...getAgreementTemplate(), ...agreement };
      })
      store.commit('set_agreements', agreements)
    } 

  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
  }
}

const controller = {
  DASHBOARD_TAB_DEFAULT: DASHBOARD_TAB_DEFAULT,
  DASHBOARD_TAB_TOTAL: DASHBOARD_TAB_TOTAL,
  DASHBOARD_TAB_INDEX: DASHBOARD_TAB_INDEX,


  load_username: load_username,
  load_profile: load_profile,
  send_profile: send_profile,

  new_property: new_property,
  load_properties: load_properties,
  load_property_address: load_property_address,
  load_property: load_property,
  send_property_photo: send_property_photo,
  send_property: send_property,

  new_tenant: new_tenant,
  load_tenants: load_tenants,
  load_tenant: load_tenant,
  send_tenant: send_tenant,
  remove_tenant: remove_tenant,
  submit_rent_reminder: submit_rent_reminder,
  confirm_rent_payment: confirm_rent_payment,

  total_yearly_expense_by_ID,
  load_expenses,
  send_expense,

  goto_page: goto_page,
  open_page: open_page,
  open_link: open_link,
  history_page: history_page,
  refresh_page: refresh_page,

  bind_back_button_on_nav_menu_tab: bind_back_button_on_nav_menu_tab,
  upload_property_img: upload_property_img,
  handle_property_record,
  load_user_notifications,
  read_user_notification,
  del_user_notification,
  getCookie,
  stripHtml,
  getAuthorizedUser,
  logoutUser,
  getAgreementTemplate,
  createOrUpdateAgreement,
  load_agreements,
}

export default controller;
