/**
 * Trigger lease gtag
 * @param {Object} gtag
 * @param {string} page_name
 * @param {string} id
 */
export const trigger_gtag_lease_document = (gtag, page_name, id) => {
  if (gtag && page_name === 'TenantAgreement' && id === 'guest') {
    gtag('event', 'Lease_Landing', {
      event_category: 'TenantAgreement',
      event_action: 'Clicked Create Document',
      event_label: 'Create Document as Guest',
    });
  }
};

/**
 * Trigger free trial gtag
 * @param {Object} gtag
 */
export const trigger_gtag_free_trial = gtag => {
  if (gtag) {
    gtag('event', 'Free_Trial_Signup', {
      event_category: 'Homepage',
      event_action: 'Clicked Sign up',
      event_label: 'Free Trial sign up',
    });
  }
};

/**
 * Trigger Dashboard gtag
 * @param {Object} gtag
 * @param {string} page_name
 * @param {string} fromPath
 */
export const trigger_gtag_dashboard = (gtag, page_name, fromPath) => {
  if (gtag && page_name === 'Dashboard') {
    gtag('event', 'Free_Trial_Signup', {
      event_category: 'Dashboard',
      event_action: `Redirected to Dashboard`,
      event_label: `${fromPath} -> Dashboard`,
    });
  }
};
