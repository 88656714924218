import Vue from "vue";
import Router from "vue-router";

import Layout from "../layout/index.vue";


Vue.use(Router);

const router = new Router({
  mode: 'history', // enable to avoid displaying # in URL
  routes: [
    {
      path: "/index.html",
      name: "Home",
      component: () => import('../components/Home.vue'),
      meta: {
        auth: false
      }
    },
    {
      path: "/",
      name: "Default",
      component: () => import('../components/Home.vue'),
      meta: {
        auth: false
      }
    },
    {
      path: "/gift",
      name: "Gift",
      component: () => import('../pages/open/Gift.vue'),
      meta: {
        auth: false, 
      }
    },
    {
      path: "/invoice",
      name: "Invoice",
      component: () => import('../pages/open/Invoice.vue'),
      meta: {
        auth: false,
      }
    },
    {
      path: "/tool/invoice-ck",
      name: "InvoiceCN",
      component: () => import('../pages/open/Invoice-ck.vue'),
      meta: {
        auth: false,
      }
    },
    {
      path: '/unsubscribed',
      component: () => import('../pages/open/Unsubscribed.vue'),
      name: 'Unsubscribed',
      meta: {
        auth: false,
      }
    },
    {
      path: "/blog",
      name: "Blog",
      component: () => import('../pages/open/Blog.vue'),
      meta: {
        auth: false,
      }
    },
    {
      path: "/SupportUs",
      name: "SupportUs",
      component: () => import('../components/Home-SupportUs.vue'),
      meta: {
        auth: false
      }
    },
    {
      path: "/Payment",
      name: "Payment",
      component: () => import('../components/Home-Payment.vue'),
      meta: {
        auth: false
      }
    },
    {
      path: "/LeaseLanding",
      name: "LeaseLanding",
      component: () => import('../components/Home-LeaseLanding.vue'),
      meta: {
        auth: false
      }
    },
    {
      path: '/tenant/agreement/:id?', redirect: '/lease/:id?',
      meta: {
        auth: false,
        keepAlive: false
      }
    },
    {
      path: '/lease/:id?',
      component: () => import('../pages/open/TenantAgreement.vue'),
      name: 'TenantAgreement',
      meta: {
        auth: false,
        keepAlive: false
      }
    },
    {
      path: "/Contact",
      name: "Contact",
      component: () => import('../components/Footer-Contact.vue'),
      meta: {
        auth: false
      }
    },
    {
      path: "/AboutUs",
      name: "AboutUs",
      component: () => import('../components/Footer-AboutUs.vue'),
      meta: {
        auth: false
      }
    },
    {
      path: "/TermsOfUse",
      name: "TermsOfUse",
      component: () => import('../components/Footer-TermsOfUse.vue'),
      meta: {
        auth: false
      }
    },
    {
      path: "/PrivacyPolicy",
      name: "PrivacyPolicy",
      component: () => import('../components/Footer-PrivacyPolicy.vue'),
      meta: {
        auth: false
      }
    },
    {
      path: "/logout",
      name: "Logout",
      component: () => import('../components/Auth-Logout.vue'),
      meta: {
        auth: false
      }
    },
    {
      path: "/auth",
      name: "Auth",
      component: () => import('../pages/auth/index.vue'),
      meta: {
        auth: false
      }
    },
    {
      path: "/social_response",
      name: "SocialReturn",
      component: () => import('../pages/auth/components/socialReturn.vue'),
      meta: {
        auth: false
      }
    },
    {
      path: "/change_password",
      name: "ChangePassword",
      component: () => import('../components/Auth-ChangePassword.vue'),
    },
    
    {
      path: "/reset_password",
      name: "ResetPassword",
      component: () => import('../components/Auth-ResetPassword.vue'),
      meta: {
        auth: false
      }
    },
    {
      path: "/dashboard",
      name: "Dashboard",
      component: Layout,
      redirect: '/propertylist/index',
    },
    // {
    //   path: "/properties",
    //   name: "Properties",
    //   component: () => import('../components/Properties.vue'),
    // },
    // {
    //   path: "/property",
    //   name: "Property",
    //   component: () => import('../components/Property.vue'),
    // },
    {
      path: "/tenant",
      name: "Tenant",
      component: () => import('../components/Tenant.vue'),
    },
    {
      path: "/rent_balance",
      name: "RentBalance",
      component: () => import('../components/Tenant-Balance.vue'),
      meta: {
        auth: false
      }
    },
    {
      path: "/rent_receipts",
      name: "RentReceipts",
      component: () => import('../components/Tenant-Receipts.vue'),
      meta: {
        auth: false
      }
    },
    {
      path: "/accounting",
      name: "Accounting",
      component: () => import('../components/Prop-Accounting.vue'),
    },
    {
      path: "/precaution",
      name: "Precaution",
      component: () => import('../components/Prop-Precaution.vue'),
    },
    {
      path: "/expense",
      name: "Expense",
      component: () => import('../components/Prop-Expense.vue'),
    },
    {
      path: "/finance",
      name: "Finance",
      component: () => import('../components/Prop-Finance.vue'),
      meta: {
        auth: false
      }
    },
    {
      path: '/propertylist',
      component: Layout,
      name: 'PropertyList',
      redirect: '/propertylist/index',
      meta: {
        title: 'Properties',
        icon: 'property',
        type: 'nav1',
        i18n: 'breadcrumb.list_property'
      },
      children: [
        {
          path: 'index',
          component: () => import('../pages/property/list/index.vue'),
          name: 'PropertiesIndex',
        },
        {
          path: 'add',
          component: () => import('../pages/property/new/index.vue'),
          name: 'AddProperty',
          meta: {
            title: 'AddProperty',
            i18n: 'property.add_property'
          },
        },
        {
          path: 'detail',
          component: () => import('../pages/property/detail/index.vue'),
          name: 'PorpertyDetail',
          meta: {
            title: 'PorpertyDetail',
            i18n: 'property.property_detail'
          },
        }
      ]
    },
    {
      path: '/rentals',
      component: Layout,
      name: 'Rentals',
      redirect: '/rentals/index',
      meta: {
        title: 'Rentals',
        icon: 'rent',
        type: 'nav1',
        i18n: 'breadcrumb.list_tenant'
      },
      children: [
        {
          path: 'index',
          component: () => import('../pages/tenants/list/index.vue'),
          name: 'RentalsIndex',
        },
        {
          path: 'add',
          component: () => import('../pages/tenants/new/index.vue'),
          name: 'AddTenant',
          meta: {
            title: 'AddTenant',
            i18n: 'tenant.add_tenant'
          },
        },
        {
          path: 'detail',
          component: () => import('../pages/tenants/detail/index.vue'),
          name: 'TenantDetail',
          meta: {
            title: 'TenantDetail',
            i18n: 'tenant.tenant_detail'
          },
        },
        {
          path: 'leasepreview',
          component: () => import('../pages/tenants/lease/preview.vue'),
          name: 'Preview',
          meta: {
            title: 'TenantDetail',
            i18n: 'tenant.tenant_detail'
          }
        },
        {
          path: 'receipt',
          component: () => import('../pages/tenants/receipt/index.vue'),
          name: 'ReceiptHistory',
          meta: {
            title: 'ReceiptHistory',
            i18n: 'tenant.receipt_history'
          },
        },
        {
          path: 'newlease',
          component: () => import('../pages/tenants/lease/index.vue'),
          name: 'NewLease',
          meta: {
            title: 'TenantDetail',
            i18n: 'tenant.tenant_detail'
          }
          
        },
        

      ]
    },
    {
      path: '/vacancy',
      component: Layout,
      name: 'Vacancy',
      children: [
        {
          path: '',
          component: () => import('../pages/vacancy/index.vue'),
          name: 'NewVacancy',
        }
      ]
    },
    {
      path: '/utilities',
      component: Layout,
      name: 'Utilities',
      redirect: '/utilities/index',
      meta: {
        title: 'Utilities',
        icon: 'utility',
        type: 'nav1',
        i18n: 'breadcrumb.list_utilities'
      },
      children: [
        {
          path: 'index',
          component: () => import('../components/template/_Utilities.vue'),
          name: 'UtilitiesIndex',
        }
      ]
    },
    // {
    //   path: '/maintenance',
    //   component: Layout,
    //   name: 'Maintenance',
    //   redirect: '/maintenance/index',
    //   meta: {
    //     title: 'Maintenance',
    //     icon: 'maintenance',
    //     type: 'nav1',
    //     i18n: 'breadcrumb.list_maintenance'
    //   },
    //   children: [
    //     {
    //       path: 'index',
    //       component: () => import('../components/template/_Empty.vue'),
    //       name: 'MaintenanceIndex',
    //     }
    //   ]
    // },
    // {
    //   path: '/financials',
    //   component: Layout,
    //   name: 'Financials',
    //   redirect: '/financials/index',
    //   meta: {
    //     title: 'Financials',
    //     icon: 'financial',
    //     type: 'nav1',
    //     i18n: 'breadcrumb.list_financials'
    //   },
    //   children: [
    //     {
    //       path: 'index',
    //       component: () => import('../components/template/_Empty.vue'),
    //       name: 'FinancialsIndex',
    //     }
    //   ]
    // },
    // {
    //   path: '/insurance',
    //   component: Layout,
    //   name: 'Insurance',
    //   redirect: '/insurance/index',
    //   meta: {
    //     title: 'Insurance',
    //     icon: 'insurance',
    //     type: 'nav1',
    //     i18n: 'breadcrumb.list_insurance'
    //   },
    //   children: [
    //     {
    //       path: 'index',
    //       component: () => import('../components/template/_Empty.vue'),
    //       name: 'InsuranceIndex',
    //     }
    //   ]
    // },
    {
      path: '/settings',
      component: Layout,
      name: 'Settings',
      redirect: '/settings/index',
      meta: {
        title: 'Settings',
        icon: 'setting',
        type: 'nav2',
        i18n: 'breadcrumb.settings'
      },
      children: [
        {
          path: 'index',
          component: () => import('../components/template/_Settings.vue'),
          name: 'SettingsIndex',
        }
      ]
    },

    {
      path: '/notifications',
      component: Layout,
      name: 'Notifications',
      redirect: '/notifications/index',
      meta: {
        title: 'Notifications',
        icon: '',
        type: 'nav3',
        i18n: 'breadcrumb.list_notifications'
      },
      children: [
        {
          path: 'index',
          component: () => import('../components/template/_Notifications.vue'),
          name: 'NotificationsIndex',
        }
      ]
    },
    {
      path: "/payment_result",
      name: "PaymentResult",
      component: () => import('../pages/stripe/payResult.vue'),
      meta: {
        auth: false
      }
    },
    // {
    //   path: "*",
    //   name: "NotFound",
    //   component: AuthLogout
    // }
  ],
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return {
        selector: to.hash,
        behavior: 'smooth'
      }
    }
    else if (savedPosition) {
      return savedPosition;
    }
    else {
      return { x: 0, y: 0 }
    }
  },
});

export default router