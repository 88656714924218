<template>
  <div>
    <a v-show="!showForm" id="feedback" @click.stop.prevent="showForm = true">
      <b-img :src="require('@/assets/feedback.svg')"/>
    </a>

    <div v-show="showForm" id="feedback-form">
      <div class="top-menu">
        <p>Leave us a message</p>
        <button
          class="rounded-circle bg-light d-flex justify-content-center align-items-center"
          @click="closeForm"
        >
          <span/>
        </button>
      </div>

      <div class="conten">
        <div v-show="showSuccessMsg">
          <p class="pt-4">
            Your feedback is very important to us!
          </p>
          <p>We will reply you as soon as possible.</p>
          <p>Thank you!</p>
        </div>
        <form v-show="!showSuccessMsg" @submit.stop.prevent="submit">
          <div>
            <label for="fb-email">
              Your Email
            </label>
            <input
              id="fb-email"
              v-model="formData.replyto"
              type="email"
              name="replyto"
              :disabled="disabled"
            >
          </div>
          <div>
            <label for="fb-msg">
              Your questions or feedback here
              <span class="text-danger">
                *
              </span>
            </label>
            <textarea
              id="fb-msg"
              v-model="formData.message"
              name="message"
              required
              :disabled="disabled"
            />
          </div>
          <div class="d-flex align-items-center">
            <input type="submit" :disabled="disabled" :value="submitText">
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { external_api } from '@/helper/api_handle';

export default {
  name: 'Feedback',
  data() {
    return {
      showForm: false,
      showSuccessMsg: false,
      disabled: false,
      submitText: 'Submit',
      formData: {
        replyto: '',
        message: ''
      }
    }
  },
  watch: {
    'formData.message': function (newMessage) {
      if (newMessage.trim() === '') {
        this.formData.message = '';
      }
    }
  },
  methods: {
    closeForm: function () {
      if (this.showSuccessMsg) {
        this.showSuccessMsg = false;
      }
      this.showForm = false;
    },
    submit: async function (e) {
      if (this.formData.message !== '') {
        const form_data = new FormData(e.target)
        try {
          this.disabled = true;
          this.submitText = 'Submiting...';
          const formspreeKEY = process.env.VUE_APP_FORM_SPREE_KEY;
          const res = await external_api.post(`https://formspree.io/f/${formspreeKEY}`, form_data)

          if (res.status === 200) {
            this.showSuccessMsg = true;
            this.formData = {
              replyto: '',
              message: ''
            }
            return
          }
          throw new Error(res.data)
        } catch (error) {
          // eslint-disable-next-line no-console
          console.error('Error submiting feedback')
        } finally {
          this.disabled = false;
          this.submitText = 'Submit';
        }
      }
    }
  }
}
</script>

<style scoped lang='less'>
#feedback {
  border-width: 1px;
  border-style: solid;
  border-color: white;
  z-index: 999;
  position: fixed;
  bottom: 80px;
  right: 30px;
  background: rgb(135 101 243 / 0.8);
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  -webkit-border-radius: 35px;
  -moz-border-radius: 35px;
  border-radius: 35px;
  /* display: none; */
  -webkit-transition: all 0.3s linear;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  background-color: #7B4FFF !important;
}

#feedback:hover{
background: #8E69FD !important;
color: #fff;
}

#feedback:active{
background: #6B42E6 !important;
color: #fff;
}

#feedback img {
  width: 60%;
  height: 100%;
}
#feedback:hover {
  background: rgb(135 101 243 / 1);
  cursor: pointer;
}

#feedback-form {
  z-index: 999;
  position: fixed;
  bottom: 80px;
  right: 2rem;
  display: flex;
  flex-flow: column;
  width: 82%;
  max-width: 23rem;
  background: white;
  border: #8765f3;
  border-radius: 0.4rem;
  box-shadow: #383838 -1px 3px 10px 1px;
}

#feedback-form .top-menu {
  background: #8765f3;
  padding: 1.5rem 1rem;
  border-top-right-radius: 0.4rem;
  border-top-left-radius: 0.4rem;
}
#feedback-form .top-menu p {
  color: #ffffff;
  font-size: 1.3rem;
  font-weight: bold;
  margin: 0;
}
#feedback-form .top-menu > button {
  width: 1.2rem;
  height: 1.2rem;
  border: none;
  position: absolute;
  top: 1.8rem;
  right: 1.2rem;
  padding: 0.2rem;

  & > span {
    background-color: #8765f3;
    height: 0.2rem;
    width: 100%;
  }
}
#feedback-form .conten {
  padding: 2rem 2rem;
}

#feedback-form .conten p {
  color: #8765f3;
}

#feedback-form form {
  display: flex;
  flex-flow: column;
}

#feedback-form form div {
  display: flex;
  flex-flow: column;
  padding-bottom: 1.5rem;
}

#feedback-form form label {
  text-align: left;
  font-weight: bold;
  font-size: 1rem;
}

#feedback-form form textarea {
  min-height: 10rem;
  resize: vertical;
}

#feedback-form form input,
#feedback-form form textarea {
  border: 1px solid #cecece;
  line-height: 1.2rem;
  padding: 0.5rem 0.8rem;
  border-radius: 0.2rem;
  height: 3rem;
}

#feedback-form form input[type='submit'] {
  background-color: #7B4FFF !important;//#8765f3;
  box-shadow: 0px 8px 24px 2px rgba(0, 0, 0, 0.2);
  padding: 0.6rem;
  width: 8rem;
  border-radius: 5px; //5rem;
  color: #ffffff;
}

#feedback-form form input[type='submit']:hover {
  background-color: #8E69FD !important;
}

#feedback-form form input[type='submit']:active {
  background-color: #6B42E6 !important;
}

</style>
