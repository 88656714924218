<template>
  <div class="side-wrapper">
    <div class="sidebar-container">
      <div class="side-top">
        <div @click.stop.prevent="goto_homepage()">
          <b-img
            class="nav-title-icon"
            :src="
              isMobile ? '/static/logo-circle.png' : '/static/logo-short.svg'
            "
            :width="isMobile ? 50 : 200"
          />
        </div>
      </div>
      <hr class="split">
      <div class="scrollbar-wrapper">
        <template>
          <div
            v-for="item in routes1"
            :id="'left-' + item.name"
            :key="item.path"
            :class="{ active: $route.path === item.redirect }"
            class="menu-item"
            @click="goToPage(item.path)"
          >
            <div class="icon-container">
              <svg-icon :icon-class="item.meta.icon" class-name="icon"/>
            </div>
            <div class="label">
              {{ translate(item.meta.i18n) }}
            </div>
          </div>

          <hr>
          <div class="space"/>

          <div
            v-for="item in routes3"
            :key="item.path"
            class="menu-item"
            @click="goToPage(item.path)"
          >
            <div class="bell-container">
              <div>
                <t-notification
                  v-if="true"
                  :count="0"
                  :size="24"
                  :upper-limit="50"
                  counter-background-color="#7b4fff"
                  left="150px"
                  font-size="18px"
                  icon-color="#A0A5BA"
                />
                <span v-if="newNotifs > 0">
                  {{ newNotifs }}
                </span>
              </div>
            </div>
            <div
              style="margin-left: 64px"
              class="label"
              data-i18n="breadcrumb.list_notifications"
            />
          </div>
          <div
            v-for="item in routes2"
            :id="'left-' + item.name"
            :key="item.path"
            class="menu-item"
            @click="goToPage(item.path)"
          >
            <div class="icon-container">
              <svg-icon :icon-class="item.meta.icon" class-name="icon"/>
            </div>
            <div class="label">
              {{ translate(item.meta.i18n) }}
            </div>
          </div>

          <div class="space"/>
          <div class="menu-item" @click="handleLogout">
            <div class="icon-container">
              <svg-icon class-name="icon" icon-class="logout"/>
            </div>
            <div class="label">
              {{ translate('general.logout') }}
            </div>
          </div>
        </template>
      </div>
    </div>
    <div class="version">
      {{ app_version }}
    </div>
  </div>
</template>
<script>
import i18next from 'i18next'
import { mapGetters } from "vuex";
import NotificationBell from "vue-notification-bell";
import ResizeMixin from '@/mixins/resize'
import { logoutUser } from '@/helper/controller'

export default {
  components: {
    "t-notification": NotificationBell,
  },
  mixins: [ResizeMixin],
  props: {},
  data() {
    return {
      newNotifs: 0,
    }
  },
  computed: {
    ...mapGetters({
      authorized: "authorized",
    }),
    app_version: function () {
      return `${this.isMobile ? 'v' : 'Version:'} ${process.env.APP_VERSION}`
    },
    routes1() {
      return this.$router.options.routes.filter((e) => {
        if (e.meta && e.meta.type === "nav1") {
          return e;
        }
      });
    },
    routes2() {
      return this.$router.options.routes.filter((e) => {
        if (e.meta && e.meta.type === "nav2") {
          return e;
        }
      });
    },
    routes3() {
      return this.$router.options.routes.filter((e) => {
        if (e.meta && e.meta.type === "nav3") {
          return e;
        }
      });
    },
  },
  created() { },
  mounted() { },
  methods: {
    show_menu() { },
    translate(input) {
      return i18next.t(input)
    },
    goToPage(path) {
      if (this.$route.path !== path) {
        this.$router
          .push({
            path,
          })
          .catch(() => { });
      }
    },
    goto_homepage() {
      this.$router.replace('/')
    },
    handleLogout() {
      logoutUser()
    },
  },
};
</script>
<style scoped lang="less">
.side-wrapper {
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;
}

.sidebar-container {
  padding-top: 40px;
  box-sizing: border-box;
  flex: 1;
  width: 100%;
  .side-top {
    width: 80%;
    margin: 0 auto;
  }

  .split {
    width: 90%;
    margin: 20px auto;
  }

  .scrollbar-wrapper {
    .menu-item {
      width: 90%;
      margin: 0 auto;
      height: 50px;
      color: #74798c;
      font-size: 16px;
      border-radius: 3px;
      cursor: pointer;
      text-align: left;
      overflow: hidden;
      line-height: 50px;
      position: relative;

      &:hover {
        color: #7b4fff !important;
        background: rgba(224, 215, 253, 0.35) !important;
      }

      &.active {
        color: #7b4fff !important;
        background: rgba(224, 215, 253, 0.35) !important;
      }

      .icon-container {
        width: 30px;
        font-size: 20px;
        text-align: left;
        display: inline-block;
        margin: 0 15px;
      }

      .label {
        text-align: left;
        font-weight: 500;
        display: inline-block;
      }
    }
    .space {
      height: 80px;
      width: 100%;
    }
  }
}
.version {
  font-size: 1rem;
  margin-top: 30px;
}

.notification {
  display: flex;
  flex-direction: row;
  border: 1px solid black;
  margin-left: 0.7rem;
  height: max-content;

  &:hover {
    color: #7b4fff !important;
  }
}

.bell-container {
  margin-left: 12px;
  margin-top: 15px;
}

.bell-container,
.bell-container > div {
  position: absolute;
}

.bell-container #notificationBell + span {
  width: 1.5rem;
  height: 1.5rem;
  background: #8765f3;
  display: flex;
  font-size: 0.6rem;
  justify-content: center;
  align-items: center;
  line-height: 1rem;
  position: absolute;
  top: 0;
  left: 0.9rem;
  border-radius: 2rem;
  border: 1px solid #ffffff;
  color: white;
  margin-left: 175px;
}

.border1 {
  border: 1px solid black;
}
</style>