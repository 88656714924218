import { translate } from "@/helper/translator";

export default {
  mounted() {
    translate()
  },
  activated() {
    translate()
  }
}
