<template>
  <section class="app-main">
    <transition mode="out-in" name="fade-transform">
      <keep-alive :exclude="excludes">
        <router-view/>
      </keep-alive>
    </transition>
  </section>
</template>
<script>
export default {
  name: 'AppMain',
  data() {
    return {
      excludes: "TenantAgreement,Utilities,NewProperty,NewTenant,ReceiptHistory,PropertyData,NewLease,Preview",
    }
  },
  computed: {
    key() {
      return this.$route.path
    }
  }
}
</script>
<style scoped lang="less">
.app-main {
  /* 50= navbar    */
  min-height: calc(100vh - 185px);
  width: 100%;
  position: relative;
  overflow: auto;
  background: white;
  border: 1px solid #e5e5e5;
  border-radius: 6px;
  box-sizing: border-box;
}
</style>