import LoadingComponent from './main.vue'

const Loading = {}
let showLoading = false // 存储loading显示状态
let loadingNode = null // 存储loading节点元素

Loading.install = function (Vue) {
  Vue.prototype.$loading = function (tips, type) {
    if (type === 'hide') {
      setTimeout(() => {
        loadingNode.isShowLoading = showLoading = false
      }, 300);
    } else {
      if (showLoading) {
        return
      }
      const LoadingConstructor = Vue.extend(LoadingComponent)
      loadingNode = new LoadingConstructor({
        data: {
          isShowLoading: showLoading,
          content: tips,
        },
      })
      loadingNode.$mount() // 挂在实例，为了获取下面的loadingNode.$el
      document.body.appendChild(loadingNode.$el)
      loadingNode.isShowLoading = showLoading = true
    }
  }

  const array = ['show', 'hide']
  array.forEach(function (type) {
    Vue.prototype.$loading[type] = function (tips) {
      return Vue.prototype.$loading(tips, type)
    }
  })
}

export default Loading
