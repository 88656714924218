"use strict";
import moment from "moment";
import { moment_of_previous_due_date, is_ready_for_collection, total_tenant_owing_today } from "./calculator";

export const get_today = () => moment().format('YYYY-MM-DD');
export const get_year_and_month = () => moment().format('YYYY/MM')
export const get_yesterday = (date = get_today()) => moment(date).subtract(1, 'days').format('YYYY-MM-DD');
export const get_this_month = () => moment().format('YYYY/MM');
export const get_end_of_this_month = () => moment().endOf('month').format('YYYY-MM-DD');
export const get_last_month = () => moment().subtract(1, 'months').format('YYYY/MM');
export const get_month_from_now = () => formatDateToMoment(get_today()).add(1, 'months').format('YYYY-MM-DD');
export const get_year_from_now = () => formatDateToMoment(get_today()).add(1, 'years').format('YYYY-MM-DD');
export const PAYMENT_ENTICIPATION_DAYS = 3


export const is_earlier = (date, granularity = 'day') => {
  if (date?.length > 10) {
    date = formatDate(date)
  }

  if (date?.length == 10) {
    return moment(date, 'YYYY-MM-DD').isBefore(get_today(), granularity);
  }
  else {
    return undefined
  }
}
export const is_later = (date, granularity = 'day') => {
  if (date?.length > 10) {
    date = formatDate(date)
  }

  if (date?.length == 10) {
    return moment(date, 'YYYY-MM-DD').isAfter(get_today(), granularity);
  }
  else {
    return undefined
  }
}
export const is_the_same = (date, granularity = 'day') => {
  if (date?.length > 10) {
    date = formatDate(date)
  }

  if (date?.length == 10) {
    return moment(date, 'YYYY-MM-DD').isSame(get_today(), granularity);
  }
  else {
    return undefined
  }
}
export const is_prior_to = (date, granularity = 'day', number = 0) => {
  if (date?.length > 10) {
    date = formatDate(date)
  }

  if (date?.length == 10) {
    if (moment().isSameOrAfter(date, granularity)) return false;
    else return moment().add(number, granularity).isSameOrAfter(date, granularity);
  }
  else {
    return undefined
  }
}



export const get_previous_month = (date = get_today(), number = 1) => {
  return formatDateToMoment(date).subtract(number, 'months').format('YYYY-MM-DD');
}
export const month_later_than = (date = get_today(), number = 1) => {
  return formatDateToMoment(date).add(number, 'months').format('YYYY-MM-DD');
}
export const year_later_than = (date = get_today(), number = 1) => {
  return formatDateToMoment(date).add(number, 'years').format('YYYY-MM-DD');
}

export const get_previous_year = (date = get_today(), number = 1) => {
  return formatDateToMoment(date).subtract(number, 'years').format('YYYY-MM-DD');
}

export function formatDate(date) {
  if (typeof date === 'string' && /^[0-9]{4}(-|\/)[0-9]{2}(-|\/)[0-9]{2}.*/.test(date)) {
    if (date?.length > 10) {
      date = date.split('T')[0]
    }
    return date.replace(/\//g, '-')
  }
  // else if (typeof date === 'number' && (''+date).length == 13 && /^[0-9]+/.test(date)){
  //   return moment(date).format('YYYY-MM-DD');
  // }
  // else if (typeof date?.getFullYear === 'function') {
  //   return moment(date).format('YYYY-MM-DD');
  // }
  else {
    return moment(date).format('YYYY-MM-DD');
  }
}

export function formatYearMonthToDate(date = get_today()) {
  if (typeof date === 'string' && /^[0-9]{4}(-|\/)[0-9]{2}$/.test(date)) {
    return date.replace(/\//g, '-') + '-01'
  }

  return moment(date).format('YYYY-MM-DD');
}

export function formatDateToMoment(date) {
  if (date?.length > 10) {
    return moment(formatDate(date), 'YYYY-MM-DD')
  }
  else if (date?.length == 10) {
    return moment(date, 'YYYY-MM-DD')
  }
  else if (date?.length == 7) {
    return moment(date, 'YYYY/MM')
  }
  else {
    return moment()
  }
}
export function formatDateToString(date) {
  if (date?.length >= 10) {
    return formatDateToMoment(date).format('YYYY MMMM Do');
  }
  else {
    return ''
  }
}

export function dateToMonth(date) {
  if (date?.length > 10) {
    date = formatDate(date)
  }

  if (date?.length == 10) {
    return moment(date).format('YYYY/MM');
  }
  else if (date?.length == 7) {
    return date;
  }
  else {
    return moment().format('YYYY/MM');
  }
}
export function dateToNumber(date) {
  if (date?.length > 10) {
    date = formatDate(date)
  }

  if (date?.length == 10) {
    return Number(moment(date, 'YYYY-MM-DD').format('DD'));
  }
  else {
    return 0
  }
}


/*  BEGIN Tenant Related calendar */

export function next(due_date_number) {
  if (typeof due_date_number === 'string') {
    due_date_number = parseInt(due_date_number)
  }

  if (!Number.isInteger(due_date_number)) {
    return moment().format('YYYY-MM-DD');
  }
  else if (due_date_number == 0) {
    return moment().format('YYYY-MM-DD');
  }
  else if (due_date_number > dateToNumber(get_end_of_this_month())) {
    return moment().endOf('month').format('YYYY-MM-DD');
  }
  else if (due_date_number < moment().date()) {
    return moment(due_date_number, "DD").add(1, 'months').format('YYYY-MM-DD');
  }
  else {
    return moment(due_date_number, "DD").format('YYYY-MM-DD');
  }
}
export function get_next_due_date(tenant_data) {
  if (!tenant_data.due_date_number || !Number.isInteger(tenant_data.due_date_number)) {
    let due_date_number = dateToNumber(tenant_data.rental_start_date)
    tenant_data.due_date_number = due_date_number
  }

  const months_unpaid = (total_tenant_owing_today(tenant_data) / tenant_data.rent)
  let next_due_date
  
  if(tenant_data.due_date_number < moment().date()) {
    next_due_date = moment(tenant_data.due_date_number, "DD").subtract(months_unpaid-1, 'months').format('YYYY-MM-DD');
  } else {
    next_due_date = moment(tenant_data.due_date_number, "DD").subtract(months_unpaid-1, 'months').format('YYYY-MM-DD');
  }

  return next_due_date;
}
export function get_previous_due_date(tenant_data) {
  if (tenant_data?.rental_start_date) {
    return moment_of_previous_due_date(tenant_data?.rental_start_date).format('YYYY-MM-DD');
  }
  else {
    return moment(get_last_month(), 'YYYY/MM').format('YYYY-MM-DD');
  }
}

export const is_lease_term_ended = (tenant_data) => {
  if (tenant_data?.rental_end_date) {
    return is_earlier(formatDate(tenant_data.rental_end_date))
  }
  else {
    return false
  }
}
export const is_lease_term_about_to_end = (tenant_data) => {
  if (tenant_data?.rental_end_date) {
    if (!tenant_data?.next_due_date) {
      tenant_data.next_due_date = get_next_due_date(tenant_data)
    }
    return moment(tenant_data.rental_end_date, 'YYYY-MM-DD').isBefore(tenant_data.next_due_date, 'day');
  }
  else {
    return false
  }
}

export function begin_of_next_cycle(tenant_data) {
  return tenant_data.next_due_date
}
export function end_of_next_cycle(tenant_data) {
  return get_yesterday(moment(tenant_data.next_due_date).add(1, 'month'))
}


export function number_of_days_before_due_date(tenant_data) {
  get_next_due_date(tenant_data);
  return moment().diff(tenant_data.next_due_date, 'days') * (-1)
}

export function is_close_to_due_date(tenant_data, days_number = PAYMENT_ENTICIPATION_DAYS) {
  if (is_ready_for_collection(tenant_data) || tenant_data.owing < 0) return false

  tenant_data.next_due_date ||= next(tenant_data.due_date_number)
  return is_prior_to(tenant_data.next_due_date, 'day', days_number)
}
export function is_next_due_date_far_away(tenant_data) {
  return !is_close_to_due_date(tenant_data)
}

export function is_free_to_go(tenant_data) {
  return !is_ready_for_collection(tenant_data) && is_next_due_date_far_away(tenant_data)
}

export function is_reminder_sent(tenant_data) {
  if (is_earlier(tenant_data.last_reminder_sent_date)) {
    tenant_data.is_reminder_sent = false // reset is_reminder_sent semaphore
  }
  return tenant_data.is_reminder_sent
}


export function number_of_days_before_lease_end(tenant_data) {
  if (tenant_data.rental_end_date) {
    const end_date = formatDateToMoment(tenant_data.rental_end_date)
    return end_date.diff(get_today(), 'days')
  }
  else {
    return 0
  }
}


/* ENDOF Tenant Related calendar */



const calendar = {
  is_earlier: is_earlier,
  is_later: is_later,
  is_the_same: is_the_same,
  is_prior_to: is_prior_to,

  get_today,
  get_year_and_month,
  get_yesterday,
  get_this_month,
  get_last_month,
  get_month_from_now,
  get_end_of_this_month,
  get_year_from_now,
  get_previous_month,
  get_previous_year,
  month_later_than: month_later_than,
  year_later_than: year_later_than,

  formatDate: formatDate,
  formatDateToMoment: formatDateToMoment,
  formatYearMonthToDate: formatYearMonthToDate,
  formatDateToString: formatDateToString,
  dateToMonth: dateToMonth,
  dateToNumber: dateToNumber,

  /*  BEGIN Tenant Related calendar */
  PAYMENT_ENTICIPATION_DAYS: PAYMENT_ENTICIPATION_DAYS,
  next: next,
  get_next_due_date: get_next_due_date,
  is_lease_term_ended: is_lease_term_ended,
  is_lease_term_about_to_end: is_lease_term_about_to_end,
  get_previous_due_date: get_previous_due_date,
  begin_of_next_cycle: begin_of_next_cycle,
  end_of_next_cycle: end_of_next_cycle,
  number_of_days_before_due_date: number_of_days_before_due_date,
  is_close_to_due_date: is_close_to_due_date,
  is_next_due_date_far_away: is_next_due_date_far_away,
  is_free_to_go: is_free_to_go,
  is_reminder_sent: is_reminder_sent,
  number_of_days_before_lease_end: number_of_days_before_lease_end
  /* ENDOF Tenant Related calendar */
}

export default calendar;
