<template>
  <div class="language-dropdown">
    <b-dropdown right>
      <template slot="button-content">
        <span class="lang-text" data-i18n="flag"/>
      </template>
      <b-dropdown-item
        v-for="(lang, i) in available_languages"
        :key="i"
        @click="handleSelectLanguage(lang)"
      >
        <div :data-i18n="'language.' + lang"/>
      </b-dropdown-item>
    </b-dropdown>
  </div>
</template>

<script>
import translator from '@/helper/translator'
import { goto_page } from '@/helper/controller'

export default {
  data: function () {
    return {
      available_languages: [
        'en',
        'fr',
        'cns'
      ],
      selected_language: this.$route.query.language || this.language || localStorage.getItem('language') || window.navigator.language.toLowerCase().replace('-', '_') || 'en'
    }
  },
  computed: {
    language() {
      return this.$store.state.language
    }
  },
  watch: {
    selected_language: function (new_language) {
      this.$store.commit('set_language', new_language)
      goto_page(this.$route.name, Object.assign({}, this.$route.query, { language: new_language }))
    }
  },
  mounted() {
    translator.translate()
  },
  methods: {
    handleSelectLanguage(value) {
      this.selected_language = value
    }
  }
}
</script>

<style scoped lang="less">
.language-dropdown {
  width: 40px;
  height: 35px;
  text-align: left;
  outline: none;

  .dropdown {
    width: 40px;
    background: transparent;
    .lang-text {
      font-size: 24px;
    }
    /deep/ .btn {
      background: transparent !important;
      border: 0;
      text-align: left;
      &:focus {
        outline: 0;
      }
    }

    /deep/ .btn-secondary {
      &.dropdown-toggle:focus {
        box-shadow: 0 0 0 0 transparent !important;
      }
    }
  }
}
</style>
