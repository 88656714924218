<template>
  <div class="header-container">
    <div class="dashboard-nav">
      <div class="language-container">
        <t-language-dropdown/>
      </div>
      <div class="mr-2 bell-container step-notification">
        <div @click="showDropDown = !showDropDown">
          <t-notification
            :count="0"
            :size="24"
            :upper-limit="50"
            counter-background-color="#7b4fff"
            counter-location="upperRight"
            counter-padding="0px 5px"
            counter-style="roundRectangle"
            counter-text-color="#FFFFFF"
            font-size="12px"
            icon-color="#d8d8d8"
          />
          <span v-if="newNotification">
            {{ newNotification }}
          </span>
        </div>
        <div v-show="showDropDown" class="notif-drdwn">
          <ul>
            <li :class="['notid-hd', newNotification ? 'new' : '', 'active']">
              <b-btn class="pt-4">
                Notification
              </b-btn> <span/>
            </li>
          </ul>
          <div class="view">
            <b-btn @click="goto_notification">
              View All...
            </b-btn>
          </div>
          <div class="cnt">
            <div
              v-for="notification in notifications"
              :key="notification._id"
              :class="[
                'd-flex',
                'flex-column',
                'py-3',
                notification.read ? '' : 'unread',
              ]"
              tabindex="0"
              @click="goto_notification(notification._id)"
            >
              <div class="d-flex align-items-center">
                <div class="msg">
                  <p>{{ notification.subject }}</p>
                  <p>{{ strip(notification.content) }}</p>
                </div>
              </div>
              <p class="d-flex justify-content-end mb-0">
                {{ notification.date }}
              </p>
            </div>
          </div>
        </div>
      </div>
      <img
        class="rounded-circle btn-cur"
        height="24"
        src="/static/avatar.png"
        @click.stop.prevent="goto_auth()"
      >
      <p>
        {{ (username || email || '').toLowerCase() }}
      </p>
    </div>
  </div>
</template>
<script>
import NotificationBell from "vue-notification-bell";
import { mapGetters } from "vuex";
import { goto_page, stripHtml } from "@/helper/controller";
import LangDropdown from "@/components/template/_Language.vue";


export default {
  components: {
    "t-notification": NotificationBell,
    "t-language-dropdown": LangDropdown
  },
  props: {},
  data() {
    return {
      showDropDown: false,
    };
  },
  computed: {
    newNotification() {
      return (this.notifications.filter(not => !not.read)).length
    },
    ...mapGetters({
      authorized: "authorized",
      email: "email",
      propertyID: "propertyID",
      properties: "properties",
      tenants: "tenants",
      errors: "errors",
    }),
    username: {
      get() {
        return this.$store.state.account.username;
      },
      set(username) {
        this.$store.commit("set_username", username);
      },
    },
    notifications() {
      return this.$store.state.notifications;
    }
  },
  watch: {},
  created() {
  },
  mounted() {
    document.addEventListener('click', this.handleClickOutside, true);
  },
  beforeDestroy() {
    document.removeEventListener('click', this.handleClickOutside, true);
  },
  methods: {
    handleClickOutside(evt) {
      const dropDown = document.querySelector('.header-container .bell-container');
      if (dropDown && !dropDown.contains(evt.target)) {
        this.showDropDown = false;
      }
    },
    goto_auth() {
      this.showSignUpPromptModal = false;
      goto_page("Settings");

    },
    goto_notification(id) {
      goto_page('Notifications', { id })
      this.showDropDown = false;
    },
    strip(html) {
      return stripHtml(html)
    },
  },
};
</script>

<style scoped lang="less">
.header-container {
  height: 100%;
  background: white;
  text-align: left;

  .dashboard-nav {
    height: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    float: right;
    z-index: 200;
  }
  
  .dashboard-nav .btn-cur {
    cursor: pointer;
  }
  .dashboard-nav img {
    width: 24px;
    height: 28px;
    margin: 0 15px;
    z-index: 99;
  }
  .dashboard-nav > p {
    margin: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: right;
    padding-right: 20px;
  }

  .bell-container, .language-container {
    padding: 0 10px;
    cursor: pointer;
  }
  .bell-container,
  .bell-container > div {
    position: relative;
  }

  .bell-container #notificationBell + span {
    width: 1rem;
    height: 1rem;
    background: #8765f3;
    display: flex;
    font-size: 0.6rem;
    justify-content: center;
    align-items: center;
    line-height: 1rem;
    position: absolute;
    top: 0;
    left: 0.9rem;
    border-radius: 1rem;
    border: 1px solid #ffffff;
    color: #ffffff;
  }
  .bell-container .notif-drdwn {
    max-height: calc(100vh - 10rem);
    overflow: scroll;
    width: 25rem;
    background: #ffffff;
    position: absolute;
    left: -13.5rem;
    top: 2.5rem;
    z-index: 2;
    border: 1px solid #d4d4d4;
    box-shadow: 0px 1px 7px 0px #c1bfbf;
    border-radius: 0.2rem;
  }
  .notif-drdwn ul {
    display: flex;
    margin: 0;
    padding: 0 0 0 0;
    border-bottom: 1px solid#dadada;
  }
  .notif-drdwn li {
    list-style: none;
  }
  .notif-drdwn li.active {
    border-bottom: 2px solid #8765f3;
  }

  .notif-drdwn li.new button::after {
    content: '';
    height: 0.4rem;
    width: 0.4rem;
    background: #8765f3;
    display: block;
    border-radius: 100%;
    position: relative;
    top: 0.2rem;
  }
  .notif-drdwn li button,
  .view button {
    background: none;
    border: none;
    color: #74798c;
    font-weight: bold;
    display: flex;
    font-size: 14px;
  }
  .notif-drdwn li button:active,
  .view button:active {
    background: none;
    color: #74798c;
  }

  .view {
    display: flex;
    justify-content: flex-end;
    padding: 1rem 1.2rem;
  }

  .view button {
    text-decoration: underline;
    color: #8765f3;
    padding: 0;
  }
  .cnt {
    padding: 0;

    > div {
      padding: 0 1.2rem 0 1.2rem;
      cursor: pointer;

      &:hover {
        background: #f4f2f8;
      }
    }

    & > div > p {
      color: #979797;
      font-size: 12px;
    }

    .msg {
      overflow: hidden;
    }

    .msg p {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 100%;
      font-size: 14px;
      margin: 0;
    }
    .msg p:first-child {
      font-weight: bold;
    }
    .unread {
      background: #f8f7fb;
    }
  }
}
</style>
