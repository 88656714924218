<template>
  <transition name="modal">
    <div class="modal-mask">
      <div class="modal-wrapper" :style="!hideOnFocusOut ? 'cursor: progress;' : ''">
        <div class="modal-container">
          <div class="modal-header">
            <slot name="header">
              {{ header }}
            </slot>
          </div>

          <div class="modal-body">
            <slot name="body">
              {{ body }}
            </slot>
          </div>

          <div v-if="!hideFooter" class="modal-footer">
            <slot name="footer" class="center">
              <b-btn
                variant="success"
                class="modal-default-button"
                data-i18n="general.ok"
                @click.stop="$emit('close')"
              >
                OK
              </b-btn>
            </slot>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  props: {
    hideFooter: {
      type: Boolean,
      default: false,
    },
    hideOnFocusOut: {
      type: Boolean,
      default: false,
    },
    header: {
      type: String,
      default: ''
    },
    body: {
      type: String,
      default: ''
    },
    footer: {
      type: String,
      default: ''
    }
  },
  mounted() {
    document.addEventListener('click', this.handleClickOutside, true);
  },
  beforeDestroy() {
    document.removeEventListener('click', this.handleClickOutside, true);
  },
  methods: {
    handleClickOutside(evt) {
      const dropDown = document.querySelector('.modal-mask .modal-wrapper .modal-container');
      if (dropDown && !dropDown.contains(evt.target) && this.hideOnFocusOut) {
        this.$emit('close')
      }
    },
  }
}
</script>

<style scoped>
.modal-mask {
  position: fixed;
  z-index: 998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  z-index: 999;
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  width: 400px;
  margin: 0px auto;
  padding: 20px 30px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  font-family: Helvetica, Arial, sans-serif;
}

.modal-header {
  text-align: center;
  display: block;
}
.modal-header h3 {
  margin-top: 0;
  margin: auto;
}

.modal-body {
  margin: 20px 0;
}

.modal-footer {
  display: block;
}
.modal-footer div {
  width: 100%;
  margin: auto;
}
.modal-footer button {
  margin: auto;
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
</style>
