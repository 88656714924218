<template>
  <div v-if="isShowLoading" class="global-loading-container">
    <div class="center">
      <img class="ring" src="/static/loading.png">
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      isShowLoading: false,
      content: '',
    }
  },
  watch: {
    isShowLoading(val) {
      if (val) {
        document.body.style = 'overflow: hidden;'
      } else {
        document.body.style = ''
      }
    },
  },
}
</script>

<style scoped lang="less">
.global-loading-container {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1008;

  .center {
    margin: 40vh auto;
    text-align: center;
  }
}

.ring {
  -webkit-animation: spin 2s infinite linear;
  -moz-animation: spin 2s infinite linear;
  -o-animation: spin 2s infinite linear;
  -ms-animation: spin 2s infinite linear;
  animation: spin 2s infinite linear;
}
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@-moz-keyframes spin {
  0% {
    -moz-transform: rotate(0deg);
  }
  100% {
    -moz-transform: rotate(360deg);
  }
}
@-o-keyframes spin {
  0% {
    -o-transform: rotate(0deg);
  }
  100% {
    -o-transform: rotate(360deg);
  }
}
@-ms-keyframes spin {
  0% {
    -ms-transform: rotate(0deg);
  }
  100% {
    -ms-transform: rotate(360deg);
  }
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>