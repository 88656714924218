"use strict";
import store from "../store";
import moment from "moment";
// import { external_api } from './api_handle';
import calendar from "./calendar";
// import controller from "./controller";

const DEFAULT_DECIMAL_PLACE = 2
const CURRENCIES = [
  { code: 'CAD', symbol: '$' },
  { code: 'CNY', symbol: '¥' },
  { code: 'EUR', symbol: '€' },
  { code: 'RUB', symbol: '₽' },
  { code: 'USD', symbol: '$' }
];
const CURRENCIES_SYMBOLS = CURRENCIES.reduce((output, input) => output + ',' + input.code, '').substring(1);
let base_currency = store.state.base_currency;
let currency_data = store.state.currency_data;


export const set_base_currency = (new_currency) => {
  if (new_currency && CURRENCIES_SYMBOLS.indexOf(new_currency) >= 0) {
    base_currency = new_currency
    store.commit('set_currency', new_currency);
    return new_currency;
  }
  else {
    return base_currency;
  }
}

// export const load_currency_rates = () => {
//   const CurrencyRatesURL = `https://api.exchangeratesapi.io/latest?base=${base_currency}&symbols=${CURRENCIES_SYMBOLS}`

//   external_api.get(CurrencyRatesURL)
//     .then(function ({ data }) {
//       store.commit('set_currency_data', data);
//     });
// }
// load_currency_rates();

// export const set_currency_rates = (new_currency) => {
//   if (new_currency === base_currency) {
//     if (currency_data?.date === calendar.get_today()) {
//       return
//     }
//     else {
//       load_currency_rates();
//     }
//   }
//   else {
//     set_base_currency(new_currency);
//     load_currency_rates();
//   }
// }


/**
 * Open an external link
 * @param {number} amount
 * @param {string} new_currency
 */
export const formatAmount = (amount, new_currency=base_currency, decimal_place=DEFAULT_DECIMAL_PLACE) => {
  if (!amount || typeof amount !== 'number') return 0;

  const symbol = CURRENCIES.find(c => c.code == new_currency).symbol
  const value = (amount/1).toFixed(decimal_place).toString()
  // const format = symbol + value.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
  const format = symbol + value.replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")

  return format
}

/**
 * Open an external link
 * @param {number} amount
 * @param {string} new_currency
 */
export const view_amount_in_currency = (amount, new_currency) => {
  if (currency_data?.date === calendar.get_today()) {
    const amount_in_currency = amount / currency_data.rates[new_currency]
    return formatAmount(amount_in_currency, base_currency)
  }
  else {
    return formatAmount(amount, new_currency)
  }
}


function getUtilityTotal(expense) {
  const result = expense.electricity + expense.internet + expense.water + expense.boiler + expense.gas
  return result
}

function getTaxPerMonth(expense) {
  const result = expense.public_service / 12
  return result
}

function getInsurancePerMonth(expense) {
  const result = expense.insurance / 12
  return result
}



/*  BEGIN Rent Related Calculation */

export const total_months_for_past_rent_coverage = (rental_start_date) => {
  const moving_month = calendar.formatDate(rental_start_date)
  return moment().diff(moving_month, 'months')
}

const total_rent_due_include_history = (rental_start_date, monthly_rent) => {
  return monthly_rent * (total_months_for_past_rent_coverage(rental_start_date) + 1)
}

export const total_tenant_owing_today = (tenant_data) => {
  if (!tenant_data?.rent) return 0;

  const total_rent_paid = tenant_data?.paid - tenant_data?.deposit
  const total_rent_due = total_rent_due_include_history(tenant_data?.rental_start_date, tenant_data?.rent)
  const owing = total_rent_due - total_rent_paid
  return owing
}

export const is_rent_due_date_past = (tenant_data) => {
  return is_ready_for_collection(tenant_data)
}
export const is_ready_for_collection = (tenant_data) => {
  return tenant_data.owing > 0 || total_tenant_owing_today(tenant_data) > 0
}

export const moment_of_previous_due_date = (rental_start_date) => {
  const moving_date = calendar.formatDateToMoment(rental_start_date)
  return moving_date.add(total_months_for_past_rent_coverage(moving_date.format('YYYY-MM-DD')), 'months')
}

export const total_days_for_late_payment = (rental_start_date) => {
  return moment().diff(moment_of_previous_due_date(rental_start_date) , 'days')
}

export const number_of_days_before_lease_end = (tenant_data) => {
  return calendar.number_of_days_before_lease_end(tenant_data)
}
export const rent_expected_before_lease_end = (tenant_data) => {
  return Math.floor(calendar.number_of_days_before_lease_end(tenant_data)/30 -1) * tenant_data.rent
}

/* ENDOF Rent Related Calculation */


/*  BEGIN Property Related Calculation */

export const total_property_monthly_expense = (thisMonthExpense) => {
  if (!thisMonthExpense?.date) return 0

  const utilities = Number(thisMonthExpense?.electricity||0) + Number(thisMonthExpense?.water||0) + Number(thisMonthExpense?.internet||0) + Number(thisMonthExpense?.boiler||0) + Number(thisMonthExpense?.gas||0)

  const expense = (utilities
  // + Number(thisMonthExpense.maintenance||0)
  // + Number(thisMonthExpense.gardening||0)
  // + Number(thisMonthExpense.lawning||0)
  // + Number(thisMonthExpense.snow_removal||0)
  ).toFixed(DEFAULT_DECIMAL_PLACE)

  return Number(isNaN(expense) ? 0 : expense)
}


export const this_year_property_fixed_expense = (property_expense) => {
  if (!property_expense?.date) return 0

  const expense = (
    Number(property_expense.public_service||0)
    + Number(property_expense.insurance||0)
    + Number(property_expense.renovation||0)
    + Number(property_expense.depreciation||0)
    + Number(property_expense.terminal_loss||0)
  ).toFixed(DEFAULT_DECIMAL_PLACE)

  return Number(isNaN(expense) ? 0 : expense)
}

export const total_property_yearly_generalized_expense = (property_expenses) => {
  let thisMonthExpense = property_expenses?.find(expense => {
    return expense.date === calendar.dateToMonth()
  })
  if (!thisMonthExpense) {
    thisMonthExpense = property_expenses[0]
  }

  property_expenses = property_expenses.filter(expense => {
    return calendar.formatDateToMoment(expense.date).isAfter(calendar.get_previous_year())
  })
  if (property_expenses.length == 12){
    const this_year_varying_expenses = property_expenses?.reduce((acc, expense) => {
      acc += Number(total_property_monthly_expense(expense));
      return acc
    }, 0)

    return this_year_varying_expenses + this_year_property_fixed_expense(thisMonthExpense)
  }
  else {
    return total_property_monthly_expense(thisMonthExpense)*12 + this_year_property_fixed_expense(thisMonthExpense)
  }
}

export const property_gain_lose_this_year = (propertyID, property_expenses) => {
  return total_rent_collected_this_year(propertyID) - total_property_yearly_generalized_expense(property_expenses);
}


// const sleep = (time) => {
//   return new Promise((resolve) => setTimeout(resolve, time));
// }
export const total_property_monthly_rent = (property_id) => {
  let monthly_rent = 0
  if (store.state.tenants?.length > 0) {
    const tenants = store.state.tenants.filter(t => t.property === property_id)
    tenants.forEach(t => monthly_rent += ((t.rent > 0 && t.rent < 9999) ? t.rent : 0))
  }
  else {
    // controller.load_tenants()
    // sleep(1000).then(() => {
    //   return total_property_monthly_rent(property_id)
    // });
  }
  return monthly_rent
}

export const total_property_rent_owing = (property_id) => {
  let rent_owing = 0
  const tenants = store.state.tenants.filter(t => t.property === property_id)
  tenants.forEach(t => rent_owing += ((t.owing > 0 && t.owing < 9999) ? t.owing : 0))
  return rent_owing
}

export const total_rent_collected_this_year = (property_id) => {
  if (store.state.tenants?.length > 0) {
    const tenants = store.state.tenants.filter(t => t.property === property_id)
    const totalExpectedRentThisYear = tenants.reduce((acc, tenant) => {
      const currMonth = moment().month();

      if (moment(tenant.rental_start_date).year() === moment().year()) {
        const expectedRentThisYear = ((currMonth - moment(tenant.rental_start_date).month() + 1) * tenant.rent)
        acc += expectedRentThisYear - (tenant.owing > 0 ? tenant.owing : 0);
        return acc;
      }

      const expectedRentThisYear =  (currMonth + 1) * tenant.rent;
      acc += tenant.owing > expectedRentThisYear ? 0 : expectedRentThisYear - tenant.owing;
      return acc
    }, 0);

    return totalExpectedRentThisYear
  }
}

export const property_rent_collected_this_month = (property_id) => {
  return total_property_monthly_rent(property_id) - total_property_rent_owing(property_id)
}

export const property_rent_over_due_from_past_months = (property_id) => {
  let rent_over_due = 0
  const tenants = store.state.tenants.filter(t => t.property === property_id)
  tenants.forEach(t => rent_over_due += ((t.owing > t.rent) ? (t.owing - t.rent) : 0))
  return rent_over_due
}

export const property_rent_owing_this_month_till_now = (property_id) => {
  return total_property_rent_owing(property_id) - property_rent_over_due_from_past_months(property_id)
}

const property_rent_expected_for_past_of_this_month = (property_id) => {
  return property_rent_collected_this_month(property_id) + property_rent_owing_this_month_till_now(property_id)
}

export const property_rent_expected_for_rest_of_this_month = (property_id) => {
  return total_property_monthly_rent(property_id) - property_rent_expected_for_past_of_this_month(property_id)
}


// function monthly_gain_or_lose () {
//   return (property.rent - property.monthly_expense - current_mortgage_interest).toFixed(DEFAULT_DECIMAL_PLACE)
// }

// function roi_per_year () {
//   if (property.down_payment) {
//     return ((appreciation_per_year - yearly_expense + monthly_gain_or_lose * 12) / property.down_payment * 100).toFixed(DEFAULT_DECIMAL_PLACE)
//   } else {
//     return 0
//   }
// }


/* ENDOF Property Related Calculation */


/*  BEGIN Mortgage Related Calculation */

const APPRECIATION_RATE_PER_YEAR = 0.05;

function past_purchase_years (move_in_date) {
  return moment().diff(calendar.formatDateToMoment(move_in_date), 'years')
}
function past_purchase_months (move_in_date) {
  return moment().diff(calendar.formatDateToMoment(move_in_date), 'months')
}

function appraisal (purchase_price, move_in_date) {
  const result = purchase_price * Math.pow(1 + APPRECIATION_RATE_PER_YEAR, past_purchase_years(move_in_date))
  return result
}
function appreciation (purchase_price, move_in_date) {
  const result = appraisal(purchase_price, move_in_date) - purchase_price
  return result
}
function appreciation_per_year (purchase_price, move_in_date) {
  const v_past_purchase_years = past_purchase_years (move_in_date)
  if (v_past_purchase_years > 0) {
    const result = appreciation(purchase_price, move_in_date) / v_past_purchase_years
    return result
  } else {
    return 0
  }
}

function monthly_interest_rate (interest_rate) {
  if (interest_rate) {
    return Math.pow(Math.pow(1 + interest_rate / 100 / 2, 2), 1.0 / 12) - 1
  } else {
    return 0
  }
}

function amortization_months (amortization_years) {
  return amortization_years * 12
}
function remaining_amortization_months (amortization_years, move_in_date) {
  return amortization_months(amortization_years) - past_purchase_months(move_in_date)
}

function initial_mortgage_interest (principal, interest_rate) {
  const result = principal * monthly_interest_rate(interest_rate)
  return result
}
function initial_principal_repayment (principal, interest_rate, amortization_years) {
  const v_initial_mortgage_interest = initial_mortgage_interest(principal, interest_rate)
  if (v_initial_mortgage_interest) {
    const result = v_initial_mortgage_interest /
      (Math.pow(
        1 + monthly_interest_rate(interest_rate),
        amortization_months(amortization_years)
      ) - 1)
    return result
  } else {
    return 0
  }
}
function mortgage_payment (principal, interest_rate, amortization_years) {
  const v_initial_mortgage_interest = initial_mortgage_interest(principal, interest_rate)
  const v_initial_principal_repayment = initial_principal_repayment(principal, interest_rate, amortization_years)
  const result = (v_initial_mortgage_interest + v_initial_principal_repayment)
  return result
}

function current_principal_repayment (principal, interest_rate, amortization_years, move_in_date) {
  const v_initial_principal_repayment = initial_principal_repayment(principal, interest_rate, amortization_years)
  const v_monthly_interest_rate = monthly_interest_rate (interest_rate)
  if (v_initial_principal_repayment) {
    const result = (v_initial_principal_repayment *
      Math.pow(
        1 + v_monthly_interest_rate,
        past_purchase_months(move_in_date)
      ))
    return result
  } else {
    return 0
  }
}
function this_month_principal_repayment (property) {
  const result = current_principal_repayment (property.mortgage_principal, property.interest_rate, property.amortization, property.move_in_date)
  return result
}
function last_month_principal_repayment (property) {
  const result = current_principal_repayment (property.mortgage_principal, property.interest_rate, property.amortization, calendar.month_later_than(property.move_in_date))
  return result
}

function current_mortgage_interest (principal, interest_rate, amortization_years, move_in_date) {
  const v_mortgage_payment = mortgage_payment (principal, interest_rate, amortization_years)
  const v_current_principal_repayment = current_principal_repayment (principal, interest_rate, amortization_years, move_in_date)
  const result = v_mortgage_payment - v_current_principal_repayment
  return result
}
function this_month_mortgage_interest (property) {
  const result = current_mortgage_interest (property.mortgage_principal, property.interest_rate, property.amortization, property.move_in_date)
  return result
}
function last_month_mortgage_interest (property) {
  const result = current_mortgage_interest (property.mortgage_principal, property.interest_rate, property.amortization, calendar.month_later_than(property.move_in_date))
  return result
}

function past_principal_repayment (principal, interest_rate, amortization_years, move_in_date) {
  const v_initial_principal_repayment = initial_principal_repayment(principal, interest_rate, amortization_years)
  const v_monthly_interest_rate = monthly_interest_rate (interest_rate)
  if (v_initial_principal_repayment) {
    const result = v_initial_principal_repayment / v_monthly_interest_rate *
      (Math.pow(
        1 + v_monthly_interest_rate,
        past_purchase_months(move_in_date)
      ) - 1)
    return result
  } else {
    return 0
  }
}
function remaining_principal (principal, interest_rate, amortization_years, move_in_date) {
  const v_past_principal_repayment = past_principal_repayment(principal, interest_rate, amortization_years, move_in_date)
  const result = principal - v_past_principal_repayment
  return result
}

/* ENDOF Mortgage Related Calculation */


function getMortgageInterest(expense) {
  const result = expense.mortgage_interest
  return result
}

function getprincipalRepayment(expense) {
  const result = expense.principal_repayment
  return result
}

const calculator = {
  set_base_currency: set_base_currency,
  // load_currency_rates: load_currency_rates,
  // set_currency_rates: set_currency_rates,
  formatAmount: formatAmount,
  view_amount_in_currency: view_amount_in_currency,
  getUtilityTotal: getUtilityTotal,
  getTaxPerMonth: getTaxPerMonth,
  getInsurancePerMonth: getInsurancePerMonth,
  getMortgageInterest: getMortgageInterest,
  getprincipalRepayment: getprincipalRepayment,

  /*  BEGIN Rent Related Calculation */
  total_months_for_past_rent_coverage: total_months_for_past_rent_coverage,
  total_tenant_owing_today: total_tenant_owing_today,
  is_ready_for_collection: is_ready_for_collection,
  is_rent_due_date_past: is_rent_due_date_past,
  moment_of_previous_due_date: moment_of_previous_due_date,
  total_days_for_late_payment: total_days_for_late_payment,
  number_of_days_before_lease_end: number_of_days_before_lease_end,
  rent_expected_before_lease_end: rent_expected_before_lease_end,
  /* ENDOF Rent Related Calculation */

  /*  BEGIN Property Related Calculation */
  total_property_monthly_expense: total_property_monthly_expense,
  this_year_property_fixed_expense: this_year_property_fixed_expense,
  total_property_monthly_rent: total_property_monthly_rent,
  total_property_rent_owing: total_property_rent_owing,
  total_rent_collected_this_year: total_rent_collected_this_year,
  property_rent_collected_this_month: property_rent_collected_this_month,
  property_rent_over_due_from_past_months: property_rent_over_due_from_past_months,
  property_rent_owing_this_month_till_now: property_rent_owing_this_month_till_now,
  property_rent_expected_for_rest_of_this_month: property_rent_expected_for_rest_of_this_month,
  total_property_yearly_generalized_expense,
  property_gain_lose_this_year,
  /* ENDOF Property Related Calculation */

  /*  BEGIN Mortgage Related Calculation */
  past_purchase_years: past_purchase_years,
  past_purchase_months: past_purchase_months,
  appraisal: appraisal,
  appreciation: appreciation,
  appreciation_per_year: appreciation_per_year,
  monthly_interest_rate: monthly_interest_rate,
  amortization_months: amortization_months,
  remaining_amortization_months: remaining_amortization_months,
  initial_mortgage_interest: initial_mortgage_interest,
  initial_principal_repayment: initial_principal_repayment,
  mortgage_payment: mortgage_payment,
  current_principal_repayment: current_principal_repayment,
  this_month_principal_repayment: this_month_principal_repayment,
  last_month_principal_repayment: last_month_principal_repayment,
  current_mortgage_interest: current_mortgage_interest,
  this_month_mortgage_interest: this_month_mortgage_interest,
  last_month_mortgage_interest: last_month_mortgage_interest,
  past_principal_repayment: past_principal_repayment,
  remaining_principal: remaining_principal
  /* ENDOF Mortgage Related Calculation */
}

export default calculator;
