"use strict";
import i18next from 'i18next';
import jQuery from "jquery";
const $ = jQuery;

function unescapeHtml(safe) {
  return safe
    .replace(/&amp;/g, "&")
    .replace(/&lt;/g, "<")
    .replace(/&gt;/g, ">")
    .replace(/&quot;/g, '"')
    .replace(/&#039;/g, "'");
}

export function translate () {
  if ($(".breadcrumb").length > 0) {
    $(".breadcrumb").each(function() {
      $(this).html(unescapeHtml($(this).html()))
    });
  }
  if ($("th").length > 0) {
    $("th").each(function() {
      $(this).html(unescapeHtml($(this).html()))
    });
  }
  $("head").localize(); // translate tab title
  $("#app div").localize(); // translate app content
}

export function localize (input) {
  return i18next.t(input);
}
export function translate_tag(input) {
  return i18next.t("util.unEscapeTag", {
    tag: '<b data-i18n="' + input + '"></b>'
  });
}


const translator = {
  translate: translate,
  localize: localize,
  translate_tag: translate_tag
}

export default translator;
