<template>
  <div class="bread-container">
    <div v-for="(item, index) in items" :key="item.path" class="bread-item">
      <span v-if="index === items.length - 1" class="current">
        {{ translate(item.meta.i18n) }}
      </span>
      <router-link v-else :to="item.path" class="nav-link">
        {{ translate(item.meta.i18n) }}
      </router-link>
    </div>
  </div>
</template>
<script>
import i18next from "i18next";

export default {
  components: {},
  props: {},
  data() {
    return {
      items: [],
    };
  },
  computed: {},
  watch: {
    $route() {
      this.getBreadcrumb();
    },
  },
  created() {
    this.getBreadcrumb();
  },
  mounted() { },
  methods: {
    translate(input) {
      return i18next.t(input);
    },
    getBreadcrumb() {
      let matched = this.$route.matched.filter(
        (item) => item.meta && item.meta.title
      );
      const first = matched[0];

      if (!this.isDashboard(first)) {
        matched = [
          {
            path: "/dashboard",
            meta: { title: "Dashboard", i18n: "breadcrumb.list_dashboard" },
          },
        ].concat(matched);
      }

      this.items = matched.filter(
        (item) => item.meta && item.meta.title && item.meta.breadcrumb !== false
      );
    },
    isDashboard(route) {
      const name = route && route.name;
      if (!name) {
        return false;
      }
      return (
        name.trim().toLocaleLowerCase() === "Dashboard".toLocaleLowerCase()
      );
    },
  },
};
</script>
<style scoped lang="less">
.bread-container {
  text-align: left;
  width: 100%;
  height: 80px;
  align-items: center;
  box-sizing: border-box;
  display: flex;
  .bread-item {
    margin: 0;
    height: 100%;
    display: flex;
    align-items: center;
    background: transparent;
    margin: 0 0px;
    font-size: 14px;

    .current {
      font-weight: bolder;
    }
  }

  .bread-item + .bread-item:before {
    display: inline-block;
    padding-right: 8px;
    color: #6c757d;
    content: '/';
  }

  .nav-link {
    color: #7b4fff;
    padding: 8px 10px 8px 0px;
    font-weight: 300;

    &:hover {
      font-weight: 900;
    }
  }
}
</style>