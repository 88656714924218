'use strict'
// src/helper/api_handle.js

// For production builds, require a VUE_APP_API_BASE_URL environment variable to
// be set -- this will result in `baseURL` being statically embedded in the
// build instead of being derived at runtime.
//
// By default in development and test, requests are filtered through the mock
// API in `tests/mock-api`. To test directly against a local/live API instead,
// run development and test commands with the API_BASE_URL environment variable
// set. (See also: `devServer` setting in `vue.config.js`.)

import axios from 'axios'
import { logoutUser } from './controller'
import i18next from "i18next"

const api_handle = axios.create({
  baseURL: '/api/',
})

// response interceptor
api_handle.interceptors.response.use(
  response => {
    if (response.data?.error === 'unauthorized') {
      logoutUser()
    }
    return response
  },
  error => {
    if (
      error.request?.responseURL?.indexOf('/api/newsletter') > -1 || 
      error.request?.responseURL?.indexOf('/api/pricing') > -1 || 
      error.request?.responseURL?.indexOf('/api/auth/user') > -1
    ) {
      return
    }

    if (error?.response?.status === 401 || error?.response?.status == 404) {
      logoutUser()
    }
    return Promise.reject(i18next.t(`error.${error}`))
  }
)


export const external_api = axios;
export const external_cors_api = axios.create({
});

external_cors_api.interceptors.request.use(config => {
  return {
    ...config,
    url: 'https://cors-anywhere.herokuapp.com/' + config.url
  }
});

export default api_handle;
