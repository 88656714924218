<template>
  <div class="app-wraper">
    <div class="left-container">
      <t-side-bar/>
    </div>
    <div id="app-right-container" class="right-container">
      <div class="header-container">
        <t-header/>
      </div>
      <div class="main-container">
        <t-breadcrumb/>
        <t-app-main/>
      </div>
      <t-feedback/>
    </div>
  </div>
</template>


<script>
import SideBar from "./components/Sidebar.vue";
import AppMain from "./components/AppMain.vue";
import Header from "./components/Header.vue";
import Feedback from "../components/template/_Feedback.vue";

import Breadcrumb from "./components/Breadcrumb.vue";
import { load_profile, load_user_notifications, load_tenants, load_properties } from '../helper/controller';
import { mapGetters } from 'vuex';

export default {
  components: {
    "t-side-bar": SideBar,
    "t-app-main": AppMain,
    "t-header": Header,
    "t-breadcrumb": Breadcrumb,
    "t-feedback": Feedback,

  },
  props: {},
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      authorized: 'authorized',
      email: 'email',
      propertyID: 'propertyID',
      properties: 'properties',
      tenants: 'tenants',
      errors: 'errors',
    }),
  },
  mounted() {
    this.$store.commit('set_authorized', true)

    load_properties();
    load_tenants();
    load_profile();
    load_user_notifications();
  },
  methods: {
  },
};
</script>
<style scoped lang="less">
.app-wraper {
  position: relative;
  height: 100vh;
  width: 100%;
  display: flex;

  .left-container {
    width: 260px;
    box-sizing: border-box;
    background-color: white;
    transition: width 0.28s;
  }

  .right-container {
    flex: 1;
    height: 100vh;
    overflow: auto;
    background: #f5f6fa;
    transition: all 0.28s;
    .header-container {
      height: 55px;
    }
    .main-container {
      padding: 0 30px 50px 30px;
      min-height: calc(100vh - 55px);
      box-sizing: border-box;
    }
  }
}

@media screen and (max-width: 1020px) {
  .left-container {
    width: 60px !important;
  }
}
</style>
