"use strict";

// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from "vue";
import App from "./App.vue";
import "./helper/controller"; // hack: Circular reference with store.js
import store from "./store";
import router from "./router";
import commonMixin from '@/mixins/common.js'

// import VueResource from 'vue-resource'
import VueAnalytics from 'vue-analytics';
import BootstrapVue from "bootstrap-vue";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import '../src/components/SvgIcon/index.js' // icon
import { ToggleButton } from 'vue-js-toggle-button'
import Modal from "../src/components/template/_Modal.vue";
import EventBus from './helper/eventbus'
// import Message from './components/Message/index'
import Loading from './components/Loading/index'
import 'vue-tour/dist/vue-tour.css'
import VueTour from 'vue-tour'
import './permission'
import './locales/index.js'



Vue.use(VueTour)
Vue.component('Toggle', ToggleButton)
Vue.component("Modal", Modal);

// Vue.use(Message);

Vue.use(Loading)
Vue.use(EventBus)
// Vue.use(VueResource);

Vue.mixin(commonMixin)

Vue.use(VueAnalytics, {
  id: 'UA-112438178-3',
  router
});

Vue.use(BootstrapVue);
process.env.BOOTSTRAP_VUE_NO_WARN = true;
Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app');