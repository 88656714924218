<template>
  <div class="my-tour">
    <div v-if="inStep" class="y-tour-layer"/>
    <v-tour
      name="myTour"
      :steps="steps"
      :options="options"
      :callbacks="myCallbacks"
    />
  </div>
</template>
<script>
import { goto_page } from '@/helper/controller'
import router from '../../router/index'
export default {
  components: {
  },
  props: {
  },
  data() {
    const that = this
    return {
      inStep: false,
      steps: [
        {
          target: '#step-add-property',
          content: `Click here to add property!`,
          params: {
            enableScrolling: false,
            placement: 'left',
            modifiers: {
              preventOverflow: {
                enabled: false,
                boundariesElement: document.getElementById('app-right-container')
              },
              offset: {
                offset: '0px,10px'
              },
              shift: {
                enabled: false
              }
            },
          },
          before: () => new Promise((resolve) => {
            if (that.$route.name !== 'PropertiesIndex') {
              router.push({ name: 'PropertiesIndex', }).then(() => {
                setTimeout(() => {
                  resolve()
                }, 100);
              }).catch(() => { })
            } else {
              resolve()
            }
          })
        },
        {
          target: '#left-Rentals',
          content: `Click here to manage your rentals after adding property`,
          params: {
            placement: 'right',
            modifiers: {
              preventOverflow: { enabled: false },
              offset: {
                offset: '0px,10px'
              }
            },
          }
        },
        {
          target: '#step-add-tenant',
          content: `Click here to add your tenant`,
          params: {
            placement: 'bottom',
            modifiers: {
              offset: {
                offset: '0px,15px',
              },
            },

          },
          before: () => new Promise((resolve) => {
            if (that.$route.name !== 'RentalsIndex') {
              router.push({ name: 'RentalsIndex', }).then(() => {
                setTimeout(() => {
                  resolve()
                }, 100);
              }).catch(() => { })
            } else {
              resolve()
            }
          })
        },
        {
          target: '.step-notification',
          content: `Click here to check your messages`,
          params: {
            placement: 'bottom',
            modifiers: {
              offset: {
                offset: '15px,15px'
              }
            },
          }
        },
        {
          target: '#feedback',
          content: `Click here to give us your feedback`,
          params: {
            placement: 'left',
          }
        },
        {
          target: '#left-Settings',
          content: `Click here to change your settings `,
          params: {
            placement: 'right',
            modifiers: {
              preventOverflow: { enabled: false },
              offset: {
                offset: '0px,15px'
              }
            },
          }
        },
      ],
      options: {
        enableScrolling: true,
        highlight: true,
        labels: {
          buttonSkip: 'Skip',
          buttonPrevious: 'Previous',
          buttonNext: 'Next',
          buttonStop: 'Done'
        }
      },
      myCallbacks: {
        onFinish: that.stepFinish,
        onSkip: that.stepFinish
      }

    }
  },
  computed: {
  },
  watch: {
  },
  created() {
  },
  mounted() {
    this.$eventBus.$on(
      'startTour',
      () => {
        this.inStep = true
        this.$tours['myTour'].start()
      },
      this
    )
  },
  methods: {
    stepFinish() {
      this.inStep = false
      goto_page('PropertiesIndex')
    }
  },
}
</script>
<style scoped lang="less">
.my-tour {
  .v-tour {
    /deep/ .v-step {
      background: white;
      color: #000000;
      text-align: left;
      font-size: 18px;
      font-weight: 400;
      border-radius: 10px;
      border: 2px solid #6764fd;

      .v-step__arrow {
        // background: white;
        // border: 2px silod #6764fd;
        border-width: 2px;

        background: white;
        height: 16px;
        width: 16px;
        transform: rotate(45deg);
      }

      .v-step__buttons {
        text-align: right;
        .v-step__button {
          color: #6764fd;
          font-size: 16px;
          text-decoration: underline #6764fd;
        }
      }
    }

    /deep/ .v-step[x-placement^='bottom'] {
      .v-step__arrow {
        border-color: #6764fd;
        border-right-color: transparent;
        border-bottom-color: transparent;
      }

      &#v-step-7486932c {
        .v-step__arrow {
          left: 57%;
        }
      }
    }

    /deep/ .v-step[x-placement^='left'] {
      .v-step__arrow {
        border-color: #6764fd;
        border-left-color: transparent;
        border-bottom-color: transparent;
      }
    }

    /deep/ .v-step[x-placement^='top'] {
      .v-step__arrow {
        border-color: #6764fd;
        border-top-color: transparent;
        border-left-color: transparent;
      }
    }

    /deep/ .v-step[x-placement^='right'] {
      .v-step__arrow {
        border-color: #6764fd;
        border-right-color: transparent;
        border-top-color: transparent;
      }
    }
  }

  .y-tour-layer {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 9999;
    pointer-events: none;
  }
}
</style>

<style lang="less">
.v-tour__target--highlighted {
  pointer-events: none;
  box-shadow: 0 0 0 4px #6764fd;

  z-index: 100004 !important;

  &#left-Rentals,
  &#left-Settings {
    color: #6764fd !important;
    background: white !important;
  }

  &.step-notification {
    color: #6764fd !important;
    background: white !important;

    svg {
      fill: #6764fd;
    }
  }
}
</style>